export default {
  en: {
    cancellationPolicy: "Cancellation policy",
    cancellationExplainer:
      "How flexible are you for cancellations? The more flexible you are, the more guests will want to book with you.",
    flexible: "Flexible",
    moderate: "Moderate",
    strict: "Strict",
    flexibleExplainer: "Offer a full refund 1 day prior to arrival",
    moderateExplainer: "Offer a full refund until 5 days prior to arrival",
    strictExplainer: "Offer 100% refund until 31 days prior to arrival.",
    canPolicyLink:
      "https://help.wechalet.com/en/articles/3318472-what-general-cancellation-policy-applies-on-wechalet"
  },
  fr: {
    cancelPolicy: "Politique d'annulation",
    cancellationExplainer:
      "Dans quelle mesure êtes-vous flexible pour les annulations? Plus vous êtes flexible, plus les invités voudront réserver avec vous.",
    flexible: "Flexible",
    moderate: "Modéré",
    strict: "Strict",
    flexibleExplainer:
      "Offre un remboursement complet jusqu'à 1 jour avant l'arrivée",
    moderateExplainer:
      "Offre un remboursement complet jusqu'à 5 jours avant l'arrivée",
    strictExplainer:
      "Offre un remboursement complet jusqu'à 31 jours avant l'arrivée",
    canPolicyLink:
      "https://help.wechalet.com/fr/articles/3318472-quelle-politique-generale-d-annulation-s-applique-sur-wechalet"
  }
};
