import { useMemo } from "react";
import { useTranslation } from "modules/shared";
import messages from "./messages";
import useInvoiceDetails from "modules/hosting-management/utils/use-invoice-details";

export default (invoice?: ReturnType<typeof useInvoiceDetails>) => {
  const lang = useTranslation(messages);

  const { taxes, total } = useMemo(() => {
    const taxes = [
      {
        name: lang.accommodationTax,
        price: invoice?.accommodationTax
      },
      {
        name: lang.taxNames.GST,
        price: invoice?.canadianFederalTax || 0
      },
      {
        name: lang.taxNames.QST,
        price: invoice?.quebecProvincialTax || 0
      },
      {
        name: lang.taxNames.HST,
        price: invoice?.harmonizedSalesTax || 0
      }
    ];
    return {
      taxes,
      total: taxes
        .reduce((previousTotal, tax) => previousTotal + Number(tax.price), 0)
        .toFixed(2)
    };
  }, [invoice, lang]);

  return {
    taxes,
    total
  };
};
