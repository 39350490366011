import { AlertModal } from "../containers/alert-modal";

/**
 * Layout modals
 *
 */
export const LAYOUT_MODAL = {
  ALERT: "ALERT"
};

/**
 * All modals of the layout module should be listed here.
 *
 */
export const layoutModals = {
  [LAYOUT_MODAL.ALERT]: AlertModal
};
