import { Booking } from "modules/shared/types";
import { FC, Fragment, useMemo } from "react";
import { HelpCircle } from "react-feather";
import { PriceRow } from "../price-row";
import { ReservationParameter } from "modules/shared/types/reservation-parameter";
import { ServiceFees } from "../service-fees";
import { useSelector } from "react-redux";
import { useTranslation } from "modules/shared";
import areBothNotZero from "modules/hosting-management/utils/are-both-not-zero";
import classes from "./host-payout-section.module.scss";
import clsx from "clsx";
import messages from "./messages";
import selectBaseCurrency from "modules/currency/store/selectors/select-base-currency";
import TriangleTooltip from "@design-library/TriangleTooltip";
import useCurrencyFormatter from "modules/currency/utils/use-currency-formatter";
import useInvoiceDetails from "modules/hosting-management/utils/use-invoice-details";

interface Props {
  booking?: Booking;
  reservation?: ReservationParameter;
}

export const HostPayoutSection: FC<Props> = (props) => {
  const { booking, reservation } = props;

  const lang = useTranslation(messages);

  const { format: formatCurrency } = useCurrencyFormatter();

  const currency = useSelector(selectBaseCurrency);

  const oldReservation = useMemo(() => {
    const params = booking?.reservation_parameters[0];
    return params;
  }, [booking]);

  /**
   * Get the original host invoices
   *
   */
  const oldInvoice = useMemo(() => {
    return oldReservation?.invoices?.find((i) => i.type === "hosting");
  }, [oldReservation]);

  /**
   * Get latest invoice
   *
   */
  const newInvoice = useMemo(() => {
    return reservation?.invoices?.find((i) => i.type === "hosting");
  }, [reservation]);

  const invoice = useInvoiceDetails(
    booking?.currency ?? currency,
    reservation,
    newInvoice
  );

  const originalInvoice = useInvoiceDetails(
    booking?.currency ?? currency,
    oldReservation,
    oldInvoice
  );

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.fillParent}>
          <h4 className={classes.noMargin}>{lang.hostPayout}</h4>
          {/* Heading */}
          <div className={clsx(classes.row, classes.marginBottom)}>
            <div className={classes.column} />
            <div className={clsx(classes.column, classes.alignEnd)}>
              <strong>{lang.original}</strong>
            </div>
            <div className={clsx(classes.column, classes.alignEnd)}>
              <strong>{lang.newValue}</strong>
            </div>
          </div>

          {/* Base price */}
          <PriceRow
            label={lang.basePrice}
            newValue={`${formatCurrency(invoice?.nightsCost)}`}
            original={`${formatCurrency(originalInvoice?.nightsCost)}`}
          />

          {/* Cleaning fee */}
          {areBothNotZero(
            invoice?.cleaningFee,
            originalInvoice?.cleaningFee
          ) && (
            <PriceRow
              label={lang.cleaningFee}
              newValue={`${formatCurrency(invoice?.cleaningFee)}`}
              original={`${formatCurrency(originalInvoice?.cleaningFee)}`}
            />
          )}

          {/* Service fee */}
          <ServiceFees
            details={invoice}
            originalDetails={originalInvoice}
            invoice={reservation?.invoice}
            invoiceType="hosting"
          />

          {/* Security deposit */}
          {areBothNotZero(
            invoice.damageProtectionFee,
            originalInvoice.damageProtectionFee
          ) && (
            <PriceRow
              label={lang.securityDeposit}
              newValue={`${formatCurrency(invoice?.damageProtectionFee)}`}
              original={`${formatCurrency(
                originalInvoice?.damageProtectionFee
              )}`}
            />
          )}

          {/* Extra services */}
          {originalInvoice?.extraServices?.map((service) => {
            return (
              <PriceRow
                key={service.name}
                label={
                  <span>
                    {service.name}
                    <TriangleTooltip
                      aria-label={lang[service.type]}
                      label={lang[service.type]}
                    >
                      <HelpCircle
                        size={20}
                        className={clsx(
                          classes.marginStart,
                          classes.tooltipIcon
                        )}
                      />
                    </TriangleTooltip>
                  </span>
                }
                newValue={formatCurrency(service.total)}
                original={formatCurrency(service.total)}
              />
            );
          })}

          {/* Coupon discount */}
          {areBothNotZero(
            invoice.couponDiscount,
            originalInvoice.couponDiscount
          ) && (
            <PriceRow
              label={lang.giftDiscount}
              newValue={`${formatCurrency(invoice?.couponDiscount)}`}
              original={`${formatCurrency(originalInvoice?.couponDiscount)}`}
            />
          )}

          {/* Discount (weekly / monthly) */}
          {areBothNotZero(invoice?.discount, originalInvoice?.discount) && (
            <PriceRow
              label={lang.discount}
              newValue={`${formatCurrency(invoice?.discount)}`}
              original={`${formatCurrency(originalInvoice?.discount)}`}
            />
          )}
        </div>
        <div>
          {/* Total */}
          <PriceRow
            label={<strong>{lang.total}</strong>}
            newValue={<strong>{formatCurrency(invoice.total)}</strong>}
            original={<strong>{formatCurrency(originalInvoice?.total)}</strong>}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default HostPayoutSection;
