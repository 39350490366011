import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";
import { produce } from "immer";
import { ExternalListing } from "modules/integrations/types/external-listing";

export default (
  state: ContainerState,
  action: PayloadAction<{ listing: ExternalListing; status?: boolean }>
): ContainerState => {
  const { type, payload } = action || {};
  switch (type) {
    case IMPORT_LISTING_TOGGLED:
      return produce(state, (draft) => {
        const oldStatus = draft.listingsToImport[payload.listing.id];
        draft.listingsToImport[payload.listing.id] =
          payload.status ?? !oldStatus;
      });
    default:
      return state;
  }
};

export const IMPORT_LISTING_TOGGLED = "IMPORT_LISTING_TOGGLED";
