import { ChevronDown, ChevronLeft, HelpCircle } from "react-feather";
import { FC, Fragment, useMemo, useState } from "react";
import { get } from "lodash";
import { PriceRow } from "../price-row";
import { useTranslation } from "modules/shared";
import classes from "./service-fees.module.scss";
import messages from "./messages";
import useCurrencyFormatter from "modules/currency/utils/use-currency-formatter";
import useHostingTaxes from "./use-hosting-taxes";
import useInvoiceDetails from "modules/hosting-management/utils/use-invoice-details";

type Props = {
  details: ReturnType<typeof useInvoiceDetails>;
  originalDetails: ReturnType<typeof useInvoiceDetails>;
};

type TaxItem = { name: string; originalValue: number; newValue: number };

const HostingFees: FC<Props> = (props) => {
  const { details, originalDetails } = props;

  const [taxesAreVisible, setTaxesAreVisible] = useState(false);

  const [serviceTaxesAreVisible, setServiceTaxesAreVisible] = useState(false);

  const { format } = useCurrencyFormatter();

  const lang = useTranslation(messages);

  const shouldShowHostTaxes = useMemo(() => {
    const { nightsCount = 0 } = details || {};
    /**
     * TODO: See why are values accessed by index
     *
     */
    const taxCode1 = get(details, "taxes[0].property_tax.tax_code");
    const taxCode2 = get(details, "taxes[2].property_tax.tax_code");
    const hasPropertyTax = !!taxCode1 || taxCode2;

    return nightsCount <= 31 && hasPropertyTax;
  }, [details]);

  const originalTaxes = useHostingTaxes(originalDetails);

  const taxes = useHostingTaxes(details);

  /**
   * We only show taxes that have a non-zero value
   * If both the original and new value are zero, we don't show it
   *
   */
  const nonZeroTaxes = useMemo(() => {
    return taxes.taxes.items
      .map((t) => {
        const originalTax = originalTaxes.taxes.items.find(
          (x) => x.name === t.name
        )?.price;
        const originalValue = Number(originalTax);
        const newValue = Number(t.price);
        if (!originalValue && !newValue) {
          return null;
        }
        return { name: t.name, originalValue, newValue };
      })
      .filter((t) => t !== null) as TaxItem[];
  }, [taxes, originalTaxes]);

  /**
   * Only show taxes if there are any
   * if both the original and new value are zero, we don't show it
   *
   */
  const hasNoTaxes = useMemo(() => {
    if (taxes.taxes.items.length < 1) {
      return true;
    }

    const total = Number(taxes.taxes.total);

    const originalTotal = Number(originalTaxes.taxes.total);

    return !total && !originalTotal;
  }, [taxes, originalTaxes]);

  return (
    <Fragment>
      <div>
        {/* Taxes */}
        {!hasNoTaxes && shouldShowHostTaxes && (
          <div>
            <PriceRow
              label={
                <div
                  role="button"
                  className={classes.buttonLike}
                  onClick={() => setTaxesAreVisible(!taxesAreVisible)}
                >
                  <div>
                    <span>{lang.taxes}</span>
                    {taxesAreVisible ? (
                      <ChevronLeft size={20} className={classes.marginStart} />
                    ) : (
                      <ChevronDown size={20} className={classes.marginStart} />
                    )}
                  </div>
                </div>
              }
              newValue={format(taxes.taxes.total)}
              original={format(originalTaxes.taxes.total)}
            />

            {taxesAreVisible &&
              nonZeroTaxes.map((t) => (
                <PriceRow
                  key={`${t.name}`}
                  label={<div className={classes.indent}>{t.name}</div>}
                  newValue={format(t.newValue)}
                  original={format(t.originalValue)}
                />
              ))}
          </div>
        )}

        {/* Service fee taxes */}
        <PriceRow
          label={
            <div
              role="button"
              className={classes.buttonLike}
              onClick={() => setServiceTaxesAreVisible(!serviceTaxesAreVisible)}
            >
              <div>
                <span>{lang.hostWechaletFee} (3%)</span>
                {serviceTaxesAreVisible ? (
                  <ChevronLeft size={20} className={classes.marginStart} />
                ) : (
                  <ChevronDown size={20} className={classes.marginStart} />
                )}
              </div>
            </div>
          }
          original={
            <span>
              -
              {format(
                Math.abs(Number(originalTaxes?.serviceFeeTaxes.total ?? 0))
              )}
            </span>
          }
          newValue={
            <span>
              -{format(Math.abs(Number(taxes?.serviceFeeTaxes.total ?? 0)))}
            </span>
          }
        />
        {/* Service fee taxes */}
        {taxes.serviceFeeTaxes.items.length > 0 &&
          serviceTaxesAreVisible &&
          taxes.serviceFeeTaxes.items.map((tax) => {
            return tax.name === lang.hostWechaletFee ? (
              <PriceRow
                key={`${tax.name}`}
                label={
                  <div className={classes.indent}>
                    {lang.hostWechaletFee} (3%)
                    <a
                      href={lang.serviceFeeHostLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <HelpCircle size={20} className={classes.marginStart} />
                    </a>
                  </div>
                }
                newValue={`-${format(details.wechaletFee)}`}
                original={`-${format(originalDetails.wechaletFee)}`}
              />
            ) : (
              <PriceRow
                key={`${tax.name}`}
                label={<div className={classes.indent}>{tax.name}</div>}
                newValue={`-${format(Math.abs(Number(tax.price)))}`}
                original={`-${format(
                  originalTaxes.serviceFeeTaxes.items.find(
                    (x) => x.name === tax.name
                  )?.price
                )}`}
              />
            );
          })}
      </div>
    </Fragment>
  );
};

export default HostingFees;
