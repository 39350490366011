import { FC } from "react";
import { getLocale } from "modules/settings/selectors";
import { useSelector } from "react-redux";
import messages from "./messages";
import Modal from "components/Modal";
import useLang from "@design-library/utils/useLang";

interface Props {
  onDismiss?: (shouldRefresh?: boolean) => void;
}

export const DeleteHardPausedListingModal: FC<Props> = (props) => {
  const { onDismiss = () => {} } = props;

  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  return (
    <Modal
      isOpen
      onRequestClose={() => onDismiss(false)}
      title={lang.modalTitle}
    >
      <div>
        <p>
          {lang.modalTextPart1}{" "}
          <a href={lang.supportMail}>{lang.supportMail}</a>{" "}
          {lang.modalTextPart2}
        </p>
      </div>
      <div />
    </Modal>
  );
};

export default DeleteHardPausedListingModal;
