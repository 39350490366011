import { useRef, useEffect } from "react";

const bodyScroll = {
  isLocked: 0,
  lock() {
    if (this.isLocked <= 0) {
      const scrollY = window.scrollY;
      const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;

      document.body.style.position = "fixed";
      document.body.style.top = `${scrollY * -1}px`;
      document.body.style.paddingRight = `${scrollBarWidth}px`;
    }

    this.isLocked += 1;
  },
  unlock() {
    if (this.isLocked > 0) {
      const scrollY = parseInt(document.body.style.top) * -1;

      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.paddingRight = "";

      window.scroll(0, scrollY);
    }

    this.isLocked -= 1;
  }
};

const useBodyScroll = () => {
  const requestedLock = useRef(false);

  const lock = () => {
    if (!requestedLock.current) bodyScroll.lock();
    requestedLock.current = true;
  };

  const unlock = () => {
    if (requestedLock.current) bodyScroll.unlock();
    requestedLock.current = false;
  };

  useEffect(() => () => {
    if (requestedLock.current) bodyScroll.unlock();
  }, []);

  return {
    lock,
    unlock
  };
};

export default useBodyScroll;
