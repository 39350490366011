import { Booking } from "modules/shared/types/booking";
import { EditBookingForm } from "../edit-booking-form";
import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { ObjectId } from "modules/shared/types";
import { ReservationParameter } from "modules/shared/types/reservation-parameter";
import { UpdateBookingRequest } from "../send-new-quote-form/types";
import { useQuery } from "react-query";
import { useTranslation } from "modules/shared";
import Button from "@design-library/Button";
import classes from "./modify-booking-modal.module.scss";
import extractPreviousParams from "modules/hosting-management/utils/extract-previous-params";
import messages from "./messages";
import Modal from "components/Modal";
import Query from "utils/Query";
import SendNewQuoteForm from "../send-new-quote-form";
import ModifyBookingFormSkeleton from "modules/hosting-management/components/modify-booking-form-skeleton/modify-booking-form-skeleton";
import Swal from "sweetalert2";

interface Props {
  bookingId: ObjectId;
  hostId: ObjectId; // used to determine if the user is the host
  onDismiss?: (booking?: Booking) => void;
}

export const ModifyBookingModal: FC<Props> = (props) => {
  const { onDismiss = () => {}, bookingId, hostId } = props;

  const [newReservation, setNewReservation] = useState<ReservationParameter>();

  const [error, setError] = useState<string | undefined>();

  const [mode, setMode] = useState<"edit" | "review">("edit");

  const lang = useTranslation(messages);

  const { data: booking, isLoading } = useQuery<Booking>(
    ["booking", bookingId],
    async () => {
      const response = await Query(`v1/bookings/${bookingId}/show-all`).get();
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false
    }
  );

  const initialParams = useMemo(() => {
    const reservation = booking?.reservation_parameters[0];
    const { cleaning_fee, cancellation_policy } =
      extractPreviousParams(booking);
    return {
      booking_id: booking?.id,
      checkin: reservation?.checkin,
      checkout: reservation?.checkout,
      adults: reservation?.adults ?? 0,
      cancellation_policy,
      children: reservation?.children || 0,
      infants: reservation?.infants || 0,
      pets: reservation?.pets || false,
      extra_services: reservation?.extra_services || [],
      nights_cost: "",
      cleaning_fee: Number(cleaning_fee) ?? "",
      security_deposit: booking?.invoice?.items?.damage_protection_fee
    } as UpdateBookingRequest;
  }, [booking]);

  const [params, setParams] = useState<UpdateBookingRequest>({});

  /**
   * Hydrate form with initial params
   *
   */
  useEffect(() => {
    setParams({ ...initialParams });
  }, [initialParams]);

  useEffect(() => {
    if (Number(newReservation?.night_cost) < 10) {
      setError(lang.basePriceError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newReservation]);

  const onApply = (newParams?: UpdateBookingRequest) => {
    if (newParams) {
      setParams(newParams);
    }
    setError(undefined);
  };

  const onError = (e: any) => {
    const oldReservation = booking?.reservation_parameters[0];
    setParams({ ...initialParams });
    setNewReservation(oldReservation);
    // if e is object render sawl error
    if (typeof e === "object") {
      Swal.fire({
        icon: "error",
        title: lang.modifyBookingErrorTitle,
        text: lang.modifyBookingError,
        showConfirmButton: false,
        timer: 4000
      });
      onDismiss();
    }
    setError(e);
  };

  const onReset = () => {
    const oldReservation = booking?.reservation_parameters[0];
    setParams({ ...initialParams });
    setNewReservation(oldReservation);
    setError(undefined);
  };

  return (
    <Modal
      isOpen
      onRequestClose={onDismiss}
      title={lang.title}
      className={`${classes.rightSidebarModal} ${
        mode === "review" ? classes.reviewMode : ""
      }`}
      renderCorner={() => {
        return mode === "review" ? (
          <div className={classes.buttonLink} onClick={() => setMode("edit")}>
            <strong>{lang.edit}</strong>
          </div>
        ) : null;
      }}
    >
      <div>
        {isLoading && <ModifyBookingFormSkeleton />}
        {!isLoading && !!params.booking_id && !!booking && (
          <>
            {mode === "edit" && (
              <EditBookingForm
                booking={booking}
                hostId={hostId}
                errorEdit={error}
                params={params}
                onChange={onApply}
                onChangeCost={setNewReservation}
                onError={onError}
                onReset={onReset}
                reservation={newReservation}
              />
            )}
            {mode === "review" && (
              <SendNewQuoteForm
                reservation={params}
                booking={booking}
                onSuccess={onDismiss}
              />
            )}
            <div>
              <div className={classes.actionContainer}>
                {mode === "edit" && (
                  <Fragment>
                    <Button
                      theme="secondary"
                      disabled={!!error || !newReservation}
                      onClick={() => setMode("review")}
                      className={classes.actionButton}
                    >
                      {lang.review}
                    </Button>
                  </Fragment>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ModifyBookingModal;
