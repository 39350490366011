import { Booking } from "modules/shared/types/booking";
import { FC, Fragment, useMemo } from "react";
import { getLocale } from "modules/settings/selectors";
import { UpdateBookingRequest } from "modules/hosting-management/containers/send-new-quote-form/types";
import { useSelector } from "react-redux";
import classes from "./booking-summary.module.scss";
import dayjs from "dayjs";
import messages from "./messages";
import useLang from "@design-library/utils/useLang";

interface Props {
  params?: UpdateBookingRequest;
  booking?: Booking;
}

export const BookingSummary: FC<Props> = (props) => {
  const { params, booking } = props;

  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  // const { host, guest } = booking ?? {};

  const nightsCount = dayjs(params?.checkout).diff(
    dayjs(params?.checkin),
    "day"
  );

  const format = (dateLike?: string) => {
    return dayjs(dateLike).format("ddd, MMM Do YYYY");
  };

  const guestsCount = useMemo(() => {
    const adults = Number(params?.adults ?? 0);
    const infants = Number(params?.infants ?? 0);
    const children = Number(params?.children ?? 0);
    return adults + infants + children;
  }, [params]);

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.row}>
          <span> {lang.booking}</span>
          <span> {String(booking?.id).split("-")[0]}</span>
        </div>

        {/* <div className={classes.row}>
          <span>{lang.guest}</span>
          <span>{guest?.firstname}</span>
        </div> */}

        {/* <div className={classes.row}>
          <span>{lang.host}</span>
          <span>{host?.firstname}</span>
        </div> */}

        <div className={classes.row}>
          <span>{lang.checkin}</span>
          <span>{format(params?.checkin)}</span>
        </div>

        <div className={classes.row}>
          <span>{lang.checkout}</span>
          <span>{format(params?.checkout)}</span>
        </div>

        <div className={classes.row}>
          <span>{lang.nights}</span>
          <span>{nightsCount}</span>
        </div>

        <div className={classes.row}>
          <span>{guestsCount ?? 0 > 1 ? lang.guests : lang.guest}</span>
          <span>{guestsCount}</span>
        </div>
      </div>
    </Fragment>
  );
};

export default BookingSummary;
