// @ts-expect-error
import * as yup from "yup";
import { useMemo, useState } from "react";

/**
 * Validates input and returns validation errors
 * similar to that of the API
 *
 * usage:
 * ```
 *  const { errors, validate, hasErrors } = useValidationSchema(
 *     schema
 *  );
 * ```
 */
export default (schema: Schema) => {
  const [errors, setErrors] = useState<MessageBag>({});

  const validate = async (params: unknown) => {
    setErrors({});
    try {
      await schema.validate(params, { abortEarly: false });
      return true;
    } catch (e) {
      // @ts-ignore
      const serialized = serializeErrors(e?.inner);
      setErrors(serialized);
      return false;
    }
  };

  const hasErrors = Object.keys(errors).length > 0;

  /**
   * Returns the first error, if there is any
   *
   */
  const firstError: MessageBag[string] | undefined = useMemo(() => {
    const firstKey = Object.keys(errors)[0];
    return firstKey ? errors[firstKey] : undefined;
  }, [errors]);

  return {
    validate,
    errors,
    hasErrors,
    firstError
  };
};

/**
 * Serialize validation errors
 *
 * @param yupErrors
 * @returns {MessageBag} errors
 */
const serializeErrors = (yupErrors: YupError[]): MessageBag => {
  const messageBag = {};

  for (const error of yupErrors) {
    messageBag[error.path] = error.message;
  }
  return messageBag;
};

/**
 * Types
 *
 */

type Schema = typeof yup;

type MessageBag = Record<string, string | string[]>;

type YupError = {
  path: string;
  message: string;
};
