export default {
  en: {
    reviewBooking:
      "Review the new booking details before sending them to the guest.",
    messageToTraveler: "Message to traveler (optional)",
    update: "Update Booking & Invite to book"
  },
  fr: {
    edit: "Modifier",
    reviewBooking:
      "Veuillez vérifier les nouveaux détails de réservation avant de les envoyer.",
    messageToTraveler: "Message au voyageur (optionnel)",
    update: "Mettre à jour & Inviter à réserver"
  }
};
