export default {
  en: {
    booking: "Booking",
    checkin: "Check In",
    checkout: "Check out",
    guest: "Guest",
    guests: "Guests",
    host: "Host",
    nights: "Nights"
  },
  fr: {
    booking: "Réservation",
    checkin: "Arrivée",
    checkout: "Départ",
    guest: "Invité",
    guests: "Invités",
    host: "Hôte",
    nights: "Nuits"
  }
};
