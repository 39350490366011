import { FC, Fragment } from "react";
import classes from "./modify-booking-form-skeleton.module.scss";
import clsx from "clsx";
import PricingSectionSkeleton from "../pricing-section-skeleton";
import Skeleton from "components/Skeleton";

interface Props {}

const ModifyBookingFormSkeleton: FC<Props> = () => {
  return (
    <Fragment>
      <div className={classes.container}>
        {/* First row */}
        <div className={clsx(classes.flex)}>
          <div className={clsx(classes.flexFill)}>
            <div className={classes.paddingSmall}>
              <Skeleton className="" />
            </div>
            <div className={classes.paddingHorizontalSmall}>
              <Skeleton className="" />
            </div>
          </div>
          <div className={clsx(classes.flexFill)}>
            <div className={clsx(classes.paddingSmall, classes.widthThird)}>
              <Skeleton className="" />
            </div>
            <div className={classes.paddingHorizontalSmall}>
              <Skeleton className="" />
            </div>
            <div className={classes.paddingSmall}>
              {/* Cancellation policy */}
              {Array.from({ length: 3 }).map((_, index) => (
                <div key={index}>
                  <div
                    className={clsx(classes.marginBottomSmall, classes.flex)}
                  >
                    {/* Box */}
                    <div className={classes.alignCenter}>
                      <Skeleton className={classes.checkboxSkeleton} />
                    </div>
                    {/* label */}
                    <div className={clsx(classes.flexFill)}>
                      <div className={classes.widthThird}>
                        <Skeleton className="" />
                      </div>
                      <div className={classes.widthSixty}>
                        <Skeleton className="" />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={clsx(classes.flex)}>
          <div className={clsx(classes.flexFill)}>
            <PricingSectionSkeleton />
          </div>
          <div className={clsx(classes.flexFill)}>
            <PricingSectionSkeleton />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ModifyBookingFormSkeleton;
