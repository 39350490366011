import PropTypes from "prop-types";
import LocalizedStrings from "react-localization";
import { useIsStillAvailable } from "./hooks";
import "./index.css";
import messages from "./messages";
import { PulseLoader } from "react-spinners";
import Swal from "sweetalert2";
import clsx from "clsx";
import classes from "./booking-actions.module.scss";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useTimer } from "redux/hooks/useTimer";

const lang = new LocalizedStrings(messages);
const GuestButtons = ({ bookingStatus, bookingId, booking, onClick, loading = [], isShowingInviteExpired, isInstantBooking }) => {
  const [
    checkIfAvailable,
    { isLoading: isCheckingIfStillAvailable }
  ] = useIsStillAvailable(bookingId, {
    onSuccess() {
      onClick("book");
    },
    onError(error) {
      if (error.status === 400) {
        Swal.fire({
          icon: "error",
          text: lang.book_error,
          showConfirmButton: false,
          showCloseButton: true
        });
      }
    }
  });
  /**
   * handler for the click event.
   * @param {Event} e dom even
   */
  const onClickHandler = (e) => {
    e.preventDefault();
    const { action } = e.target.dataset;

    if (bookingStatus !== "pre_approved" || ["cancel", "decline"].includes(action)) {
      onClick(action);
      return;
    }

    checkIfAvailable();
  };
  if (bookingStatus === "inquiry") {
    return (
      <div className="BookingActions_Container">
      { isInstantBooking &&
        <button
          onClick={onClickHandler}
          data-action="book"
          className="BookingActions_Button secondary"
        >
          {loading.includes("book") || isCheckingIfStillAvailable ? (
            <PulseLoader sizeUnit="px" size="10" color="white" loading={true} />
          ) : (
            lang.book
          )}
        </button>
        }
        <button
          onClick={onClickHandler}
          data-action="cancel"
          className="BookingActions_Button muted"
        >
          {loading.includes("cancel") ? (
            <PulseLoader sizeUnit="px" size="10" color="white" loading={true} />
          ) : (
            lang.cancel
          )}
        </button>
      </div>
    );
  }

  if (bookingStatus === "pre_approved") {
    return (
      <div>
        <div className={clsx("BookingActions_Container", classes.marginBottom)}>
          <button
            onClick={onClickHandler}
            data-action="book"
            className="BookingActions_Button secondary"
          >
            {loading.includes("book") || isCheckingIfStillAvailable ? (
              <PulseLoader
                sizeUnit="px"
                size="10"
                color="white"
                loading={true}
              />
            ) : (
              lang.book
            )}
          </button>
          <button
            onClick={onClickHandler}
            data-action="decline"
            className="BookingActions_Button muted"
          >
            {loading.includes("decline") ? (
              <PulseLoader
                sizeUnit="px"
                size="10"
                color="white"
                loading={true}
              />
            ) : (
              lang.decline
            )}
          </button>
        </div>
        <div className={classes.displayFlex}>
          {booking?.can_be_updated && (
            <button
              className={clsx("BookingActions_Button", classes.grow)}
              onClick={() => onClick("modify")}
              data-action="modify"
            >
              {loading.includes("modify") ? (
                <PulseLoader sizeUnit="px" size="10" color="white" loading />
              ) : (
                lang.modify
              )}
            </button>
          )}
        </div>
      </div>
    );
  }

  if (bookingStatus === "pending") {
    return (
      <div className="BookingActions_Container">
        <button className="BookingActions_Button" disabled={true}>
          {lang.pending}
        </button>
        <button
          onClick={onClickHandler}
          data-action="cancel"
          className="BookingActions_Button muted"
        >
          {loading.includes("cancel") ? (
            <PulseLoader sizeUnit="px" size="10" color="white" loading={true} />
          ) : (
            lang.cancel
          )}
        </button>
      </div>
    );
  }

  if (bookingStatus === "accepted") {
    return (
      <div className="BookingActions_Container">
        <button className="BookingActions_Button" disabled={true}>
          {lang.accepted}
        </button>
        <button
          onClick={onClickHandler}
          data-action="cancel"
          className="BookingActions_Button muted"
        >
          {loading.includes("cancel") ? (
            <PulseLoader sizeUnit="px" size="10" color="white" loading={true} />
          ) : (
            lang.cancel
          )}
        </button>
      </div>
    );
  }

  if (bookingStatus === "expired") {
    return (
      <div className="BookingActions_Container">
        <button className="BookingActions_Button" disabled={true}>
          {lang.expired}
        </button>
      </div>
    );
  }

  if (bookingStatus === "cancelled") {
    return (
      <div className="BookingActions_Container">
        <button className="BookingActions_Button" disabled={true}>
          {lang.cancelled}
        </button>
      </div>
    );
  }

  if (bookingStatus === "declined") {
    return (
      <div className="BookingActions_Container">
        <button className="BookingActions_Button" disabled={true}>
          {lang.declined}
        </button>
      </div>
    );
  }
  if (bookingStatus === "auto_declined") {
    return (
      <div className="BookingActions_Container">
        <button className="BookingActions_Button" disabled={true}>
          {lang.auto_declined}
        </button>
      </div>
    );
  }
  if (bookingStatus === "checkin") {
    return (
      <div className="BookingActions_Container">
        <button className="BookingActions_Button" disabled={true}>
          {lang.accepted}
        </button>
        <button
          onClick={onClickHandler}
          data-action="report_problem"
          className="BookingActions_Button muted"
        >
          {loading.includes("report_problem") ? (
            <PulseLoader sizeUnit="px" size="10" color="white" loading={true} />
          ) : (
            lang.reportProblem
          )}
        </button>
      </div>
    );
  }

  if (bookingStatus === "checkout") {
    return (
      <div className="BookingActions_Container">
        <button className="BookingActions_Button" disabled={true}>
          {lang.completed}
        </button>
        <button
          onClick={onClickHandler}
          data-action="report_problem"
          className="BookingActions_Button muted"
        >
          {loading.includes("report_problem") ? (
            <PulseLoader sizeUnit="px" size="10" color="white" loading={true} />
          ) : (
            lang.reportProblem
          )}
        </button>
      </div>
    );
  }

  if (bookingStatus === "completed") {
    return (
      <div className="BookingActions_Container">
        <button className="BookingActions_Button" disabled={true}>
          {lang.completed}
        </button>
      </div>
    );
  }

  return null;
};

const HostButtons = ({ bookingStatus, booking, onClick, loading = [], bookingCheckin, statuses }) => {
  const lastStatus = useMemo(() => {
    let lastStatus = statuses[0];
    for (const status of statuses) {
      if (dayjs(status.created_at).isAfter(lastStatus.created_at)) {
        lastStatus = status;
      }
    }

    return lastStatus;
  }, [statuses]);

  const timeLeft = useTimer(lastStatus.created_at);

  const timerFinished =
    timeLeft.days === "00" &&
    timeLeft.hours === "00" &&
    timeLeft.minutes === "00" &&
    timeLeft.seconds === "00";

  const isFirstInquiry = useMemo(() => {
    if (statuses?.length > 1) {
      return false;
    }
    const status = statuses[0];
    const itWas = status?.old_status;
    const itIs = status?.new_status;
    if (itWas === "created" && itIs === "inquiry") {
      return true;
    }
    return false;
  }, [statuses]);

  /**
   * handler for the click event.
   * @param {Event} e dom even
   */
  const onClickHandler = (e) => {
    e.preventDefault();
    const { action } = e.target.dataset;
    onClick(action);
  };

  if (bookingStatus === "inquiry") {
    return (
      <>
        <div className={clsx("BookingActions_Container", classes.marginBottom)}>
          {isFirstInquiry || timerFinished ? (
            <button
              onClick={onClickHandler}
              data-action="invite"
              className="BookingActions_Button secondary"
              data-cy="bookingActions.actionButton"
            >
              {loading.includes("invite") ? (
                <PulseLoader
                  sizeUnit="px"
                  size="10"
                  color="white"
                  loading={true}
                />
              ) : (
                lang.inviteToBook
              )}
            </button>
          ) : (
            <button
              disabled
              className="BookingActions_Button outlined"
              data-cy="bookingActions.actionButton"
            >
              {lang.pending}
            </button>
          )}

          <button
            onClick={onClickHandler}
            data-action="decline"
            className="BookingActions_Button muted"
            data-cy="bookingActions.decline"
          >
            {loading.includes("decline") ? (
              <PulseLoader sizeUnit="px" size="10" color="white" loading={true} />
            ) : (
              lang.decline
            )}
          </button>
        </div>
        <div className={classes.displayFlex}>
          {booking?.can_be_updated && (
            <button
              className={clsx("BookingActions_Button", classes.grow)}
              onClick={onClickHandler}
              data-action="modify"
            >
              {loading.includes("modify") ? (
                <PulseLoader
                  sizeUnit="px"
                  size="10"
                  color="white"
                  loading={true}
                />
              ) : (
                lang.modify
              )}
            </button>
          )}
        </div>
      </>
    );
  }

  if (bookingStatus === "pre_approved") {
    return (
      <div className="BookingActions_Container">
        <button
          className="BookingActions_Button"
          disabled={true}
          data-cy="bookingActions.actionButton"
        >
          {lang.pending}
        </button>
        <button
          onClick={onClickHandler}
          data-action="cancel"
          className="BookingActions_Button muted"
        >
          {loading.includes("cancel") ? (
            <PulseLoader sizeUnit="px" size="10" color="white" loading={true} />
          ) : (
            lang.cancel
          )}
        </button>
      </div>
    );
  }

  if (bookingStatus === "pending") {
    return (
      <div className="BookingActions_Container">
        <button
          onClick={onClickHandler}
          data-action="accept"
          className="BookingActions_Button secondary"
          data-cy="bookingActions.actionButton"
        >
          {loading.includes("accept") ? (
            <PulseLoader sizeUnit="px" size="10" color="white" loading={true} />
          ) : (
            lang.accept
          )}
        </button>
        <button
          onClick={onClickHandler}
          data-action="decline"
          className="BookingActions_Button muted"
        >
          {loading.includes("decline") ? (
            <PulseLoader sizeUnit="px" size="10" color="white" loading={true} />
          ) : (
            lang.decline
          )}
        </button>
      </div>
    );
  }

  if (bookingStatus === "accepted") {
    return (
      <div className="BookingActions_Container">
        <button
          className="BookingActions_Button"
          disabled={true}
          data-cy="bookingActions.actionButton"
        >
          {lang.accepted}
        </button>
        <button
          onClick={onClickHandler}
          data-action="cancel"
          className="BookingActions_Button muted"
        >
          {loading.includes("cancel") ? (
            <PulseLoader sizeUnit="px" size="10" color="white" loading={true} />
          ) : (
            lang.cancel
          )}
        </button>
      </div>
    );
  }

  if (bookingStatus === "expired") {
    // if bookingCheckin is in the past, show the expired button only and dont take hours into account
    if (new Date(bookingCheckin) <= new Date().setHours(0, 0, 0, 0)) {
      return (
        <div className="BookingActions_Container">
          <button className="BookingActions_Button" disabled={true}>
            {lang.expired}
          </button>
        </div>
      );
    }

    return (
      <div className="BookingActions_Container">
        <button
          onClick={onClickHandler}
          data-action="invite-after-expired"
          className="BookingActions_Button secondary"
          data-cy="bookingActions.actionButton"
        >
          {loading.includes("invite-after-expired") ? (
            <PulseLoader sizeUnit="px" size="10" color="white" loading={true} />
          ) : (
            lang.inviteToBook
          )}
        </button>
        {booking?.can_be_updated && (
          <button
            className="BookingActions_Button"
            data-action="modify"
            onClick={onClickHandler}
          >
            {lang.modify}
          </button>
        )}
      </div>
    );
  }

  if (bookingStatus === "cancelled") {
    return (
      <div className="BookingActions_Container">
        <button
          onClick={onClickHandler}
          data-action="invite"
          className="BookingActions_Button secondary"
          data-cy="bookingActions.actionButton"
        >
          {loading.includes("invite") ? (
            <PulseLoader
              sizeUnit="px"
              size="10"
              color="white"
              loading={true}
            />
          ) : (
            lang.inviteToBook
          )}
        </button>
        <button className="BookingActions_Button" disabled={true}>
          {lang.cancelled}
        </button>
      </div>
    );
  }

  if (bookingStatus === "declined") {
    return (
      <div className="BookingActions_Container">
        <button
          onClick={onClickHandler}
          data-action="invite"
          className="BookingActions_Button secondary"
          data-cy="bookingActions.actionButton"
        >
          {loading.includes("invite") ? (
            <PulseLoader sizeUnit="px" size="10" color="white" loading={true} />
          ) : (
            lang.inviteToBook
          )}
        </button>
        <button className="BookingActions_Button" disabled={true}>
          {lang.declined}
        </button>
      </div>
    );
  }
  if (bookingStatus === "auto_declined") {
    return (
      <div className="BookingActions_Container">
        <button className="BookingActions_Button" disabled={true}>
          {lang.auto_declined}
        </button>
      </div>
    );
  }
  if (bookingStatus === "checkin") {
    return (
      <div className="BookingActions_Container">
        <button className="BookingActions_Button" disabled={true}>
          {lang.accepted}
        </button>
        <button
          onClick={onClickHandler}
          data-action="report_problem"
          className="BookingActions_Button muted"
        >
          {loading.includes("report_problem") ? (
            <PulseLoader sizeUnit="px" size="10" color="white" loading={true} />
          ) : (
            lang.reportProblem
          )}
        </button>
      </div>
    );
  }

  if (bookingStatus === "checkout") {
    return (
      <div className="BookingActions_Container">
        <button className="BookingActions_Button" disabled={true}>
          {lang.completed}
        </button>
        <button
          onClick={onClickHandler}
          data-action="report_problem"
          className="BookingActions_Button muted"
        >
          {loading.includes("report_problem") ? (
            <PulseLoader sizeUnit="px" size="10" color="white" loading={true} />
          ) : (
            lang.reportProblem
          )}
        </button>
      </div>
    );
  }

  if (bookingStatus === "completed") {
    return (
      <div className="BookingActions_Container">
        <button className="BookingActions_Button" disabled={true}>
          {lang.completed}
        </button>
      </div>
    );
  }

  return null;
};

const BookingActions = ({
  locale = "en",
  type = "guest",
  bookingStatus = "inquiry",
  bookingId,
  statuses,
  loading = [],
  bookingCheckin,
  onClick,
  booking,
  isInstantBooking
}) => {
  lang.setLanguage(locale);
  return type === "guest" ? (
    <GuestButtons
      bookingStatus={bookingStatus}
      bookingId={bookingId}
      booking={booking}
      onClick={onClick}
      loading={loading}
      isInstantBooking={isInstantBooking}
    />
  ) : (
    <HostButtons
      bookingStatus={bookingStatus}
      onClick={onClick}
      booking={booking}
      statuses={statuses}
      bookingCheckin={bookingCheckin}
      loading={loading}
    />
  );
};

BookingActions.propTypes = {
  /** String indicating the locale of component */
  locale: PropTypes.oneOf(["en", "fr"]),
  /** String indicating the type of actions we are are displaying */
  type: PropTypes.oneOf(["guest", "host"]),
  booking: PropTypes.object,
  /** String indicating the booking id */
  bookingId: PropTypes.string,
  /** String indicating the checkin date of the booking */
  bookingCheckin: PropTypes.string,
  /** String indicating the current status of the booking */
  bookingStatus: PropTypes.oneOf([
    "inquiry",
    "pre_approved",
    "pending",
    "accepted",
    "checkin",
    "checkout",
    "completed",
    "cancelled",
    "declined",
    "expired"
  ]),
  /** String array with keys for buttons in loading state */
  loading: PropTypes.array,
  /** Function to be triggered when user clicks on a button  */
  onClick: PropTypes.func.isRequired,
  /** Boolean value to show or hide invite to book button once the booking is expired */
  isShowingInviteExpired: PropTypes.bool.isRequired
};

export default BookingActions;
