export default {
  en: {
    title: "Modify booking",
    edit: "Edit",
    review: "Review",
    /**
     * Validation errors
     *
     */
    errors: {
      nightsCostMustNotBeNegative: "Night cost must be positive",
      cleaningFeeMustNotBeNegative: "Cleaning fee must be positive",
      nightsCostMinimum: "Nights cost must be a minimum of {amount} per night",
      nightsCostIsInvalid: "Invalid nights cost"
    }
  },
  fr: {
    title: "Modifier la réservation",
    edit: "Modifier",
    review: "Aperçu",
    /**
     * Validation errors
     *
     */
    errors: {
      nightsCostMustNotBeNegative: "Le coût de la nuit doit être positif",
      nightsCostMinimum:
        "Le coût des nuits doit être d'au moins {amount} par nuit",
      nightsCostIsInvalid: "Le coût des nuits est invalide",
      cleaningFeeMustNotBeNegative:
        "Les frais de nettoyage doivent être positifs"
    }
  }
};
