import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import initialState from "./initial-state";

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    showModal(state, action: PayloadAction<{ type: string; params: any }>) {
      const { payload } = action;
      state.modal.type = payload.type;
      state.modal.params = payload.params;
    },
    dismissModal(state) {
      state.modal.type = null;
      state.modal.params = null;
    }
  }
});

export const { actions, reducer } = layoutSlice;
