import dayjs from "dayjs";

/**
 * Returns a Date or undefined
 *
 */
export default (dateLike?: string) => {
  if (dateLike) {
    return dayjs(dateLike).toDate();
  }
  return undefined;
};
