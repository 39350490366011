import { ContainerState } from "./types";

/**
 * Layout initial state
 *
 */
const initialState: ContainerState = {
  acceptedCookies: {},
  /**
   * This is deprecated and will be removed in the future.
   * Use the `modal` object instead.
   * @deprecated
   */
  modals: {},
  modal: {
    type: null,
    params: null
  }
};

export default initialState;
