import { HelpCircle } from "react-feather";
import { FC, Fragment } from "react";
import { Invoice } from "modules/shared/types/invoice";
import { PriceRow } from "../price-row";
import { useTranslation } from "modules/shared";
import classes from "./service-fees.module.scss";
import messages from "./messages";
import useCurrencyFormatter from "modules/currency/utils/use-currency-formatter";
import useInvoiceDetails from "modules/hosting-management/utils/use-invoice-details";
import TravellingFees from "./travelling-fees";
import HostingFees from "./hosting-fees";

type Props = {
  details: ReturnType<typeof useInvoiceDetails>;
  originalDetails: ReturnType<typeof useInvoiceDetails>;
  invoice?: Invoice;
  invoiceType: "travelling" | "hosting";
};

export const ServiceFees: FC<Props> = (props) => {
  const { details, originalDetails, invoiceType } = props;

  const { format } = useCurrencyFormatter();

  const lang = useTranslation(messages);

  return (
    <Fragment>
      {/* Service fee */}
      <PriceRow
        label={
          <div>
            <span>{lang.wechaletFee}</span>
            <a
              href={lang.serviceFeeGuestLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <HelpCircle size={20} className={classes.marginStart} />
            </a>
          </div>
        }
        newValue={format(details?.wechaletFee)}
        original={format(originalDetails?.wechaletFee)}
      />

      {/* Taxes */}
      {invoiceType === "travelling" ? (
        <TravellingFees details={details} originalDetails={originalDetails} />
      ) : (
        <HostingFees details={details} originalDetails={originalDetails} />
      )}
    </Fragment>
  );
};

export default ServiceFees;
