import { ExternalLink } from "react-feather";
import { FC, Fragment } from "react";
import { Listing } from "modules/shared/types/listing";
import classes from "./listing-card.module.scss";
import clsx from "clsx";
import Link from "modules/router/Link";
import routes from "routes";

interface Props {
  listing: Listing;
}

export const ListingCard: FC<Props> = (props) => {
  const { listing } = props;
  return (
    <Fragment>
      <div className={classes.card}>
        <figure className={classes.imgContainer}>
          <img
            className={classes.img}
            src={listing?.image}
            alt={listing?.name}
          />
        </figure>
        <div className={classes.titleContainer}>
          <Link
            route={routes.homes}
            params={{
              listingId: listing?.id
            }}
            target="_blank"
          >
            <h3 className={classes.link}>
              {listing?.name}
              <ExternalLink
                className={clsx(classes.marginStart, classes.sup)}
                size={18}
              />
            </h3>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default ListingCard;
