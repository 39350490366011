/**
 * Hosting management modals
 *
 */
export const HOSTING_MANAGEMENT_MODAL = {};

export const hostingManagementModals = {
  /**
   * For now this is empty until we add modals
   * here.
   *
   */
};
