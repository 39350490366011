import { FC, useMemo } from "react";
import { get } from "lodash";
import { getLocale } from "modules/settings/selectors";
import { useDispatch, useSelector } from "react-redux";
import Button from "@design-library/Button";
import classes from "./alert-modal.module.scss";
import dismissModal from "modules/layout/store/actions/dismiss-modal";
import messages from "./messages";
import Modal from "components/Modal";
import useLang from "@design-library/utils/useLang";

export interface Props {
  message?: string;
  title?: string;
  type: "success" | "error" | "warning";
}

export const AlertModal: FC<Props> = (props) => {
  const { message, type, title } = props;

  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  /**
   * Get title based on type
   *
   */
  const fallbackTitle = useMemo(() => {
    const titles = {
      success: lang.success,
      error: lang.error,
      warning: lang.warning
    };
    return get(titles, type, lang.alert);
  }, [type, lang]);

  const dispatch = useDispatch();

  const onDismiss = () => {
    dispatch(dismissModal());
  };
  return (
    <Modal isOpen onRequestClose={onDismiss}>
      <div>
        <div className={classes.title}>{title || fallbackTitle}</div>
        <div className={classes.body}>{message}</div>
        <div className={classes.footer}>
          <Button theme="secondary" onClick={onDismiss}>
            {lang.okay}
          </Button>
        </div>
      </div>
      <div />
    </Modal>
  );
};

export default AlertModal;
