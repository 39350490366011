import { CheckIcon } from "@design-library/PricingBox/icons";
import { ExtraService } from "modules/shared/types/extra-service";
import { FC, Fragment, useMemo } from "react";
import { getLocale } from "modules/settings/selectors";
import { ObjectId } from "modules/shared/types";
import { useSelector } from "react-redux";
import Checkbox from "@design-library/Checkbox";
import classes from "./extra-services-drop-down.module.scss";
import DropdownMenu from "@design-library/DropdownMenu";
import messages from "./messages";
import ServiceIcon from "@design-library/ServiceIcon";
import useBreakpoints from "utils/useBreakpoints";
import useLang from "@design-library/utils/useLang";

interface Props {
  disabled?: boolean;
  extraServices?: ExtraService[];
  onChange?: (value: ObjectId[]) => void;
  value?: ObjectId[];
}

export const ExtraServicesDropDown: FC<Props> = (props) => {
  const {
    extraServices = [],
    value = [],
    disabled,
    onChange = () => {}
  } = props;

  const breakpoints = useBreakpoints();

  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  const toggleLabel = useMemo(() => {
    const singular = lang.extraService;

    const plural = lang.extraServices;

    return !value.length
      ? plural
      : value.length > 1 // eslint-disable-next-line indent
      ? `${value.length} ${plural}` // eslint-disable-next-line indent
      : `${value.length} ${singular}`;
  }, [lang, value]);

  const onSelect = (id: ObjectId) => {
    const newValue: ObjectId[] = [...value];

    const index = newValue.indexOf(id);
    if (index !== -1) {
      newValue.splice(index, 1);
    } else {
      newValue.push(id);
    }

    onChange(newValue);
  };
  return (
    <Fragment>
      {disabled &&
        extraServices
          .filter((extraService) => value?.includes(extraService.id))
          .map((service) => (
            <div
              key={service.id}
              className="PricingRow"
              title={`${service.name} : ${lang[service.type]} - ${
                service.price
              }`}
            >
              <label className="PricingBox__ExtraService--label frozen">
                <span className="icon">
                  <CheckIcon size={16} color="#4f9589" />
                </span>
                <span>{service.name}</span>
              </label>
              <span>
                {lang[service.type]} - {service.price}
              </span>
            </div>
          ))}
      {!disabled && (
        // @ts-expect-error
        <DropdownMenu
          label={toggleLabel}
          ref={null}
          icon={() => <ServiceIcon />}
          staticMenu={!breakpoints.xl}
        >
          {extraServices.map((service) => (
            // @ts-expect-error
            <Checkbox
              key={service.id}
              value={!!value.includes(service.id)}
              onChange={() => onSelect(service.id)}
            >
              <span className={classes.bold}>{service.name}</span>
              <span className={classes.block}>
                {lang[service.type]} - {service.price}
              </span>
            </Checkbox>
          ))}
        </DropdownMenu>
      )}
    </Fragment>
  );
};

export default ExtraServicesDropDown;
