import { createSelector } from "reselect";
import { selectDomain } from ".";

/**
 * Returns latest exchange rates
 *
 */
export default createSelector([selectDomain], (state) => {
  return state.rates;
});
