import { Booking, ErrorResponse, ObjectId } from "modules/shared/types";
import { FC, Fragment, useEffect, useMemo } from "react";
import { HelpCircle } from "react-feather";
import { noop } from "lodash";
import { OnPriceChange } from "modules/hosting-management/containers/edit-booking-form/types";
import { PriceRow } from "../price-row";
import { ReservationParameter } from "modules/shared/types/reservation-parameter";
import { ServiceFees } from "../service-fees";
import { TextInput } from "../text-input";
import { UpdateBookingRequest } from "modules/hosting-management/containers/send-new-quote-form/types";
import { useSelector } from "react-redux";
import { useTranslation } from "modules/shared";
import areBothNotZero from "modules/hosting-management/utils/are-both-not-zero";
import Button from "@design-library/Button";
import classes from "./guest-payment-section.module.scss";
import clsx from "clsx";
import extractPreviousParams from "modules/hosting-management/utils/extract-previous-params";
import messages from "./messages";
import selectAuthId from "modules/auth/selectors/select-auth-id";
import selectBaseCurrency from "modules/currency/store/selectors/select-base-currency";
import TriangleTooltip from "@design-library/TriangleTooltip";
import useCurrencyFormatter from "modules/currency/utils/use-currency-formatter";
import useInvoiceDetails from "modules/hosting-management/utils/use-invoice-details";
import useValidationSchema from "utils/use-validation-schema";

interface Props {
  booking?: Booking;
  onApply?: () => void;
  onChange: OnPriceChange;
  onReset?: () => void;
  hostId: ObjectId;
  params: UpdateBookingRequest;
  reservation?: ReservationParameter;
  loading?: boolean;
  errorResponse?: ErrorResponse;
  validator: ReturnType<typeof useValidationSchema>;
}

export const GuestPaymentSections: FC<Props> = (props) => {
  const {
    onChange,
    params,
    booking,
    reservation,
    hostId,
    validator,
    onApply = noop,
    errorResponse,
    onReset = noop
  } = props;

  const lang = useTranslation(messages);

  const { format: formatCurrency } = useCurrencyFormatter();

  const currency = useSelector(selectBaseCurrency);

  const oldReservation = useMemo(() => {
    const params = booking?.reservation_parameters[0];
    return params;
  }, [booking]);

  /**
   * Get the travelling invoice
   *
   */
  const travellingInvoice = useMemo(() => {
    const travelling = oldReservation?.invoices?.find(
      (i) => i.type === "travelling"
    );
    return travelling;
  }, [oldReservation]);

  /**
   * Get the latest invoice travelling invoice
   *
   */
  const newInvoice = useMemo(() => {
    const travelling = reservation?.invoices?.find(
      (i) => i.type === "travelling"
    );
    return travelling;
  }, [reservation]);

  /**
   * New invoice should be used from the booking-cost endpoint
   *
   */
  const invoice = useInvoiceDetails(
    booking?.currency ?? currency,
    reservation,
    newInvoice
  );

  const originalInvoice = useInvoiceDetails(
    booking?.currency ?? currency,
    oldReservation,
    travellingInvoice
  );

  const authId = useSelector(selectAuthId);

  const isHost = useMemo(() => {
    return authId === hostId;
  }, [authId, hostId]);

  /**
   * Returns whether or not to show the cleaning fee
   * field
   *
   */
  const hasCleaningFee = useMemo(() => {
    const oldParams = extractPreviousParams(booking, "travelling");
    const cleaningFee = Number(oldParams.cleaning_fee);
    return cleaningFee > 0;
  }, [booking]);

  /**
   * On keydown, we apply changes
   * if the key is enter
   *
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onApply();
    }
  };

  useEffect(() => {
    onChange("nights_cost", originalInvoice.nightsCost, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.fillParent}>
          <h4 className={classes.noMargin}>{lang.guestPayment}</h4>

          {/* Heading */}
          <div className={clsx(classes.row, classes.marginBottom)}>
            <div className={classes.column} />
            <div className={clsx(classes.column, classes.alignEnd)}>
              <strong>{lang.original}</strong>
            </div>
            <div className={clsx(classes.column, classes.alignEnd)}>
              <strong>{lang.newValue}</strong>
            </div>
          </div>

          {/* Base price */}
          <div className={clsx(classes.row)}>
            <div className={clsx(classes.column, classes.alignCenter)}>
              {lang.basePrice}
            </div>
            <div className={clsx(classes.column, classes.alignEnd)}>
              {formatCurrency(originalInvoice.nightsCost)}
            </div>
            <div className={clsx(classes.column, classes.alignEnd)}>
              <TextInput
                classNames={{ input: classes.input }}
                value={params.nights_cost}
                type="number"
                onKeyDown={handleKeyDown}
                hasError={!!validator.errors.nights_cost}
                onBlur={() => onApply()}
                errors={errorResponse?.errors.nights_cost}
                onChange={(value) => onChange("nights_cost", value, false)}
              />
              <span className={classes.currency}>{currency}</span>
            </div>
          </div>
          <div className={clsx(classes.row, classes.marginBottom)}>
            <div className={classes.finePrint}>
              {formatCurrency(invoice.nightCost)} x {invoice.nightsCount}{" "}
              {invoice.nightsCount > 1 ? lang.nights : lang.night}
            </div>
          </div>
          {/* Cleaning fee */}
          {hasCleaningFee && (
            <div className={clsx(classes.row, classes.marginBottom)}>
              <div className={clsx(classes.column, classes.alignCenter)}>
                {lang.cleaningFee}
              </div>
              <div className={clsx(classes.column, classes.alignEnd)}>
                {formatCurrency(originalInvoice.cleaningFee)}
              </div>
              <div className={clsx(classes.column, classes.alignEnd)}>
                <TextInput
                  classNames={{ input: classes.input }}
                  onBlur={() => onApply()}
                  onKeyDown={handleKeyDown}
                  type="number"
                  hasError={!!validator.errors.cleaning_fee}
                  value={params.cleaning_fee}
                  onChange={(value) => onChange("cleaning_fee", value, false)}
                  errors={errorResponse?.errors.cleaning_fee}
                />
                <span className={classes.currency}>{currency}</span>
              </div>
            </div>
          )}
          {/* Service fee */}
          <ServiceFees
            details={invoice}
            originalDetails={originalInvoice}
            invoice={reservation?.invoice}
            invoiceType="travelling"
          />

          {!isHost && (
            <Fragment>
              <div className={clsx(classes.row, classes.marginBottom)}>
                <div className={clsx(classes.column, classes.alignCenter)}>
                  {lang.couponCode}
                </div>
                <div className={clsx(classes.column, classes.alignEnd)}>
                  {formatCurrency(originalInvoice.damageProtectionFee)}
                </div>
                <div className={clsx(classes.column, classes.alignEnd)}>
                  <TextInput
                    classNames={{ input: clsx(classes.input) }}
                    errors={errorResponse?.errors.coupon_code}
                    value={params.coupon_code}
                    onBlur={() => onApply()}
                    onChange={(value) => onChange("coupon_code", value, false)}
                  />
                  <span className={classes.currency}>{currency}</span>
                </div>
              </div>
            </Fragment>
          )}

          {/* Extra services */}
          {originalInvoice?.extraServices?.map((service) => {
            return (
              <PriceRow
                key={service.name}
                label={
                  <span>
                    <span className={classes.marginEnd}>{service.name}</span>
                    <TriangleTooltip
                      aria-label={lang[service.type]}
                      label={lang[service.type]}
                    >
                      <HelpCircle size={20} color="#4f9589" />
                    </TriangleTooltip>
                  </span>
                }
                newValue={formatCurrency(service.total)}
                original={formatCurrency(service.total)}
              />
            );
          })}
          {/* Coupon discount */}
          {areBothNotZero(
            invoice.couponDiscount,
            originalInvoice.couponDiscount
          ) && (
            <PriceRow
              label={lang.giftDiscount}
              newValue={`-${formatCurrency(invoice?.couponDiscount)}`}
              original={`-${formatCurrency(originalInvoice?.couponDiscount)}`}
            />
          )}
          {/* Discount (weekly / monthly) */}
          {areBothNotZero(invoice?.discount, originalInvoice?.discount) && (
            <PriceRow
              label={lang.discount}
              newValue={`-${formatCurrency(invoice?.discount)}`}
              original={`-${formatCurrency(originalInvoice?.discount)}`}
            />
          )}

          {/* Apply button */}
          <div className={clsx(classes.row, classes.marginBottom)}>
            <div className={classes.column} />
            <div className={clsx(classes.column, classes.alignEnd)}>
              {/* TODO: remove ts-ignore once button is properly typed */}
              {/* @ts-ignore */}
              <Button theme="link" size="small" onClick={onReset}>
                {lang.reset}
              </Button>
            </div>
            <div className={clsx(classes.column, classes.alignEnd)} />
          </div>
        </div>
        <div>
          {/* Total */}
          <PriceRow
            label={<strong>{lang.total}</strong>}
            newValue={<strong>{formatCurrency(invoice.total)}</strong>}
            original={<strong>{formatCurrency(originalInvoice?.total)}</strong>}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default GuestPaymentSections;
