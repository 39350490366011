import { convertCurrency } from "modules/currency";
import { Invoice, InvoiceItems } from "modules/shared/types/invoice";
import { ReservationParameter } from "modules/shared/types/reservation-parameter";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import selectBaseCurrency from "modules/currency/store/selectors/select-base-currency";
import selectRates from "modules/currency/store/selectors/select-rates";

type Params = Partial<ReservationParameter>;

/**
 * Files:
 * - /src/pages/dashboard/checkout/Payment.js
 * - /src/pages/dashboard/checkout/Review.js
 * - /src/pages/homes/Page.js
 * - /src/pages/homes/ContactHost/index.js
 *
 * should use this hook until we eliminate copy-pasted code, and then do a refactor to
 * minimize excessive data manipulation
 *
 */
export default (currency: string, params?: Params, invoice?: Invoice) => {
  const base = useSelector(selectBaseCurrency);

  const rates = useSelector(selectRates);

  const getFormattedItem = (item: keyof InvoiceItems) => {
    const value = invoice?.items[item] ?? 0;

    if (typeof value !== "string" && typeof value !== "number") {
      throw new Error(`Non numerical value encountered ${value}`);
    }

    return convertCurrency(value, currency, base, rates);
  };

  const nightsCount = useMemo(() => {
    if (!params?.checkin && !params?.checkout) {
      return 0;
    }
    const start = dayjs(params?.checkin);
    const end = dayjs(params?.checkout);
    return end.diff(start, "day");
  }, [params]);

  return {
    accommodationTax: getFormattedItem("accommodation_tax"),
    canadianFederalTax: getFormattedItem("canadian_federal_tax"),
    cleaningFee: getFormattedItem("cleaning_fee"),
    couponDiscount: getFormattedItem("coupon_discount"),
    damageProtectionFee: getFormattedItem("damage_protection_fee"),
    discount: getFormattedItem("discount"),
    discountPercentage: `${invoice?.items?.discount_percentage}`,
    harmonizedSalesTax: getFormattedItem("harmonized_sales_tax"),
    hasGiftCardCoupon: invoice?.items?.has_gift_card,
    nightCost: getFormattedItem("night_cost"),
    nightsCost: getFormattedItem("nights_cost"),
    nightsCount: nightsCount,
    quebecProvincialTax: getFormattedItem("quebec_provincial_tax"),
    securityDeposit: getFormattedItem("security_deposit"),
    serviceFeeCanadianFederalTax: getFormattedItem("canadian_federal_tax"),
    serviceFeeHarmonizedSalesTax: getFormattedItem("harmonized_sales_tax"),
    serviceFeeQuebecProvincialTax: getFormattedItem("quebec_provincial_tax"),
    taxes: invoice?.taxes,
    hostTaxes: invoice?.taxes_items,
    total: getFormattedItem("total"),
    totalWithDeposit: getFormattedItem("total_with_deposit"),
    waiverDeposit: getFormattedItem("damage_protection_fee"),
    wechaletFee: getFormattedItem("wechalet_fee"),
    extraServices:
      invoice?.items.extra_services?.map((service) => ({
        ...service,
        price: convertCurrency(service.price, currency, base, rates),
        total: convertCurrency(service.total, currency, base, rates)
      })) ?? []
  };
};
