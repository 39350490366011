import { configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../reducers";

/**
 * Configure the Redux store and applies middleware.
 *
 */
export default (preloadedState = {}) => {
  const sagaMiddleware = createSagaMiddleware({});

  // Create the store with saga middleware
  const middleware = [thunk, sagaMiddleware];

  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware().concat(middleware);
    },
    devTools: process.env.NODE_ENV !== "production"
  });

  if (module.hot) {
    module.hot.accept("../reducers", () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};
