const CloseIcon = ({ size = 18, color = "#4f5253" }) => (
  <svg
    width={size}
    height={size}
    fill="none"
    stroke={color}
    strokeWidth="1.1"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="1" y1="1" x2="13" y2="13"></line>
    <line x1="13" y1="1" x2="1" y2="13"></line>
  </svg>
);

export default CloseIcon;
