export default {
  en: {
    alert: "Alert",
    okay: "Okay",
    success: "Success",
    warning: "Warning",
    error: "Error"
  },
  fr: {
    alert: "Alerte",
    okay: "Fermer",
    success: "Succès",
    warning: "Avertissement",
    error: "Erreur"
  }
};
