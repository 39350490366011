import { FC, Fragment } from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import ModalComponents from "modules/layout/constants/modal-components";
import selectModal from "modules/layout/store/selectors/select-modal";

interface Props {}

export const ModalContainer: FC<Props> = () => {
  const modal = useSelector(selectModal);

  const ModalComponent = get(ModalComponents, modal.type ?? "");

  return (
    <Fragment>
      {ModalComponent ? <ModalComponent {...modal.params} /> : null}
    </Fragment>
  );
};

export default ModalContainer;
