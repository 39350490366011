import { Booking } from "modules/shared/types";
import { Invoice } from "modules/shared/types/invoice";
// @ts-expect-error
import parsePrice from "parse-price";

/**
 * Extracts the nights cost and cleaning fee from the booking
 *
 */
export default (booking?: Booking, type: InvoiceType = "hosting") => {
  const params = booking?.reservation_parameters[0];
  const invoice = params?.invoices.find((x) => x.type === type);
  const nights_cost = parsePrice(invoice?.items?.nights_cost);
  const cleaning_fee = parsePrice(invoice?.items?.cleaning_fee);
  return {
    nights_cost,
    cleaning_fee,
    cancellation_policy: booking?.cancellation_policy
  };
};

type InvoiceType = Invoice["type"];
