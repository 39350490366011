import { SYMBOLS } from "../types";
import { useSelector } from "react-redux";
import selectBaseCurrency from "../store/selectors/select-base-currency";

/**
 * Returns a currency-aware formatter
 *
 */
export default () => {
  const base = useSelector(selectBaseCurrency);

  const format = (value: string | number = 0) => {
    return `${SYMBOLS[base]}${Number(value).toFixed(2)} ${base}`;
  };

  return {
    format
  };
};
