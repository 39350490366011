import { Booking } from "modules/shared/types/booking";
import { faker } from "@faker-js/faker";
import { FC, Fragment, useState } from "react";
import { getLocale } from "modules/settings/selectors";
import { ListingCard, BookingSummary } from "modules/hosting-management";
import { noop } from "lodash";
import { parseErrorResponse } from "modules/shared/utils/parse-errors";
import { ResponseData } from "modules/shared/types/response-data";
import { TextArea } from "../../components/text-area";
import { UpdateBookingRequest } from "./types";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import Alert from "@design-library/Alert";
import Button from "@design-library/Button";
import classes from "./send-new-quote-form.module.scss";
import messages from "./messages";
import Query from "utils/Query";
import schema from "./schema";
import useLang from "@design-library/utils/useLang";
import useValidationSchema from "utils/use-validation-schema";

interface Props {
  reservation?: UpdateBookingRequest;
  booking?: Booking;
  onSuccess?: (booking: Booking) => void;
}

const SendNewQuoteForm: FC<Props> = (props) => {
  const { reservation, booking, onSuccess = noop } = props;

  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  const [params, setParams] = useState<UpdateBookingRequest>({
    ...reservation,
    message:
      process.env.NODE_ENV === "development" ? faker.lorem.sentences(2) : ""
  });

  const [updateBooking, { isLoading: isUpdatingQuote, error }] = useMutation(
    async (params: any) => {
      const result = await Query(`v1/bookings/${booking?.id}`).put(params);
      return result as ResponseData<Booking>;
    },
    {}
  );

  const validator = useValidationSchema(schema);

  const onUpdate = async () => {
    const isValid = await validator.validate(params);

    if (!isValid) {
      return;
    }

    const result = await updateBooking(params);
    if (result?.data) {
      onSuccess(result?.data);
    }
  };

  const listing = booking?.listing;

  const errorResponse = parseErrorResponse(error);

  return (
    <Fragment>
      <div>
        <div>
          {!!listing && <ListingCard listing={listing} />}
          <div className={classes.detailsContainer}>
            <div>
              <h3>{lang.availability}</h3>
            </div>
            {/* Validation */}
            <div>
              {validator.hasErrors && (
                <div className={classes.errors}>{validator.firstError}</div>
              )}
            </div>

            <div className={classes.reviewText}>
              <h5>{lang.reviewBooking}</h5>
              <div>
                {!!booking && (
                  <BookingSummary params={reservation} booking={booking} />
                )}
              </div>
            </div>

            {/* Input fields */}
            <div className={classes.fieldsContainer}>
              {/* Message field */}
              <TextArea
                rows={3}
                required
                classNames={{
                  input: "uk-textarea"
                }}
                label={lang.messageToTraveler}
                onChange={(message) => setParams({ ...params, message })}
                value={params.message}
                errors={
                  validator.errors.message || errorResponse.errors.message
                }
              />
            </div>
          </div>
        </div>
        {!!errorResponse.message && (
          <Alert type="danger" message={undefined}>
            <div>{errorResponse.message}</div>
            <div>
              {Object.entries(errorResponse.errors).map(([k, v]) => (
                <div key={k}>{v}</div>
              ))}
            </div>
          </Alert>
        )}
        <div className={classes.actionContainer}>
          <Button
            theme="secondary"
            loading={isUpdatingQuote}
            className={classes.actionButton}
            onClick={onUpdate}
          >
            {lang.update}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default SendNewQuoteForm;
