import {
  hostingManagementModals,
  HOSTING_MANAGEMENT_MODAL
} from "modules/hosting-management";
import { layoutModals, LAYOUT_MODAL } from "./modals";

/**
 * All modals used in the application should be registered here.
 *
 */
const ModalComponents = {
  ...hostingManagementModals,
  ...layoutModals
};

/**
 * Each module's modals should be spread in the MODAL object.
 *
 */
export const MODAL = {
  ...LAYOUT_MODAL,
  ...HOSTING_MANAGEMENT_MODAL
};

export default ModalComponents;
