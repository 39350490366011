import {
  CancellationPolicySelect,
  ExtraServicesDropDown,
  ListingCard,
  PricingFields
} from "modules/hosting-management";
import { BookingParams } from "./types";
import { Booking, ObjectId } from "modules/shared/types";
import { Calendar } from "modules/listing-calendar/types/calendar";
import { ExtraService } from "modules/shared/types/extra-service";
import { FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import { getLocale } from "modules/settings/selectors";
import { GuestBox } from "@design-library";
import { noop } from "lodash";
import { parseErrorResponse } from "modules/shared/utils/parse-errors";
import { PulseLoader } from "react-spinners";
import { ReservationParameter } from "modules/shared/types/reservation-parameter";
import { ResponseData } from "modules/shared/types/response-data";
import { UpdateBookingRequest } from "../send-new-quote-form/types";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import Alert from "@design-library/Alert";
import classes from "./edit-booking-form.module.scss";
import clsx from "clsx";
import DatePicker from "@design-library/DatePicker";
import dayjs from "dayjs";
import messages from "./messages";
import Query from "utils/Query";
import safeDate from "modules/hosting-management/utils/safe-date";
import schema from "./schema";
import useLang from "@design-library/utils/useLang";
import useValidationSchema from "utils/use-validation-schema";

interface Props {
  booking: Booking;
  onChange: (params?: UpdateBookingRequest) => void;
  onChangeCost: (reservation: ReservationParameter) => void;
  onError: (error: any) => void;
  onReset?: () => void;
  errorEdit: string | undefined;
  params: UpdateBookingRequest;
  hostId: ObjectId; // used to determine if the user is the host
  reservation?: ReservationParameter;
}

export const EditBookingForm: FC<Props> = (props) => {
  const {
    booking,
    onChange,
    hostId,
    onChangeCost,
    onError,
    errorEdit,
    onReset = noop,
    params,
    reservation
  } = props;

  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  const [date, setDate] = useState();

  const { data: availability, isLoading: isCalendarBusy } = useQuery<Calendar>(
    ["listing-calendar", booking.listing_id, date],
    async () => {
      const start_date = dayjs(date).format("YYYY-MM-DD");

      const result = await Query(
        `v1/listings/${booking.listing_id}/calendar`
      ).get({ start_date });

      return result?.data;
    }
  );

  const [otherError, setOtherError] = useState();

  const [calculateCosts, { isLoading: isCalculatingCosts, error, reset }] =
    useMutation(
      async (params: BookingParams) => {
        const result = await Query(`v1/bookings/${booking?.id}/cost`).post(
          params
        );
        return result as ResponseData<ReservationParameter>;
      },
      {
        onSuccess: (response) => onChangeCost(response.data),
        onError: (response) => onError(response)
      }
    );

  const validator = useValidationSchema(schema);

  const onApply = useCallback(
    async (newParams: Partial<BookingParams> = {}) => {
      const payload = {
        ...params,
        ...newParams
      };
      const result = await validator.validate(payload);

      if (!result) {
        validationErrorsContainer.current?.scrollIntoView({
          behavior: "smooth"
        });
        return;
      }

      calculateCosts(payload);
    },
    [calculateCosts, params, validator]
  );

  const onApplyDate = (newDate: { start: string; end: string }) => {
    const newParams = {
      ...params,
      checkin: newDate.start,
      checkout: newDate.end
    };
    setOtherError(undefined);
    onChange(newParams);
    onApply(newParams);
  };

  const onApplyGuests = (p: Partial<Request>) => {
    const newParams = {
      ...params,
      ...p
    };
    setOtherError(undefined);
    onChange(newParams);
    onApply(newParams);
  };

  const listing = booking.listing;

  const rules = {
    allowChildren: !!listing?.house_rules?.includes("allow-children"),
    allowInfants: !!listing?.house_rules?.includes("allow-infants"),
    allowPets: !!listing?.house_rules?.includes("allow-pets"),
    maxGuests: listing?.guests_count
  };

  const costErrorResponse = parseErrorResponse(error);

  const onCancel = () => {
    reset();
    setDate(undefined);
    onChange(undefined);
  };

  const { data: extraServicesResponse } = useQuery<
    ResponseData<ExtraService[]>
  >(["extra-services", listing.id], async () => {
    const result = await Query(
      `v1/listings/${listing.id}/extra-services`
    ).get();
    return result;
  });

  const extraServices = extraServicesResponse?.data ?? [];

  const onChangeParam = (
    key: keyof UpdateBookingRequest,
    value: any,
    apply = true
  ) => {
    const newParams = {
      ...params,
      [key]: value
    };
    onChange(newParams);
    if (apply) {
      onApply(newParams);
    }
  };

  const handleReset = () => {
    reset();
    onReset();
  };

  const validationErrorsContainer = useRef<HTMLDivElement | null>(null);

  /**
   * We calculate the costs at the very start
   * to hydrate the form
   *
   */
  useEffect(() => {
    onApply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);

  // const rates = useSelector(selectRates);

  /**
   * Calculates the minimum cost. since at all times, the cost should be at least 10 CAD
   * we convert 10 CAD to the booking currency
   *
   */
  // const minimumCost = useMemo(() => {
  //   return convertCurrencyFormatted("10", "CAD", booking.currency, rates);
  // }, [rates, booking.currency]);

  return (
    <Fragment>
      <div>
        <div>
          <ListingCard listing={listing} />
          <div>
            <h3>{lang.availability}</h3>
          </div>
          {/* Validation */}
          <div ref={validationErrorsContainer}>
            {(!!otherError || !!errorEdit) && (
              <div className={classes.errors}>
                {otherError || errorEdit}
              </div>
            )}
            {validator.hasErrors && (
              <div className={classes.errors}>
                {/* @ts-expect-error */}
                {lang.formatString(lang.errors[validator.firstError], {})}
              </div>
            )}
          </div>

          <div className={clsx(classes.firstSection, classes.marginBottom)}>
            <div className={classes.column}>
              <div className={classes.marginBottom}>
                <DatePicker
                  key={`${params.checkin}--${params.checkout}`}
                  availabilityCalendar={availability ?? {}}
                  initialEndDate={safeDate(params?.checkout)}
                  initialStartDate={safeDate(params?.checkin)}
                  isLoading={isCalendarBusy}
                  isFullWidth
                  theme="primary"
                  disableFlip
                  locale={locale}
                  onCancel={onCancel}
                  onApply={onApplyDate}
                  onError={(e) => setOtherError(e)}
                  onMonthChange={(d) => setDate(d)}
                />
              </div>
              <div className={classes.marginBottom}>
                <GuestBox
                  key={`${params.adults}--${params.children}--${params.pets}--${params.infants}`}
                  locale={locale}
                  maxGuests={rules.maxGuests}
                  isChildrenAllowed={rules.allowChildren}
                  isInfantsAllowed={rules.allowInfants}
                  isPetsAllowed={rules.allowPets}
                  onCancel={onCancel}
                  onApply={onApplyGuests}
                  applyOnClickAway
                  onError={(error) => setOtherError(error)}
                  theme="primary"
                  defaultChildrenState={params.children}
                  defaultAdultsState={params.adults}
                  defaultInfantsState={params.infants}
                  defaultPetsState={params.pets}
                />
              </div>

              {extraServices.length > 0 && (
                <div className={classes.marginBottom}>
                  <ExtraServicesDropDown
                    extraServices={extraServices}
                    value={params.extra_services}
                    onChange={(value) => onChangeParam("extra_services", value)}
                  />
                </div>
              )}
            </div>
            <div className={classes.column}>
              <CancellationPolicySelect
                value={params.cancellation_policy}
                onChange={(value) =>
                  onChangeParam("cancellation_policy", value)
                }
              />
            </div>
          </div>

          <div className={classes.marginBottom}>
            <PricingFields
              onApply={onApply}
              errorResponse={costErrorResponse}
              validator={validator}
              booking={booking}
              loading={isCalculatingCosts}
              onReset={handleReset}
              params={params}
              hostId={hostId}
              reservation={reservation}
              onChange={onChangeParam}
            />
          </div>

          <div>
            {isCalculatingCosts && (
              <div className={classes.loadingContainer}>
                <PulseLoader
                  sizeUnit="px"
                  color="#4f5253"
                  size={10}
                  loading={true}
                />
              </div>
            )}
          </div>
        </div>
        {!!costErrorResponse.message && (
          <Alert type="danger" message={undefined}>
            {/* general error message */}
            <div>{costErrorResponse.message}</div>
            <div>
              {Object.entries(costErrorResponse.errors).map(([k, v]) => (
                <div key={k}>{v}</div>
              ))}
            </div>
          </Alert>
        )}
      </div>
    </Fragment>
  );
};

export default EditBookingForm;
