import PropTypes from "prop-types";
import clsx from "clsx";

import style from "./Alert.module.scss";

const iconClasses = {
  info: "fa-info-circle",
  success: "fa-check-circle",
  danger: "fa-times-circle",
  warning: "fa-exclamation-circle"
};

/**
 * Alert component
 */
const Alert = ({ type, message: messageProp, children, ...attrs }) => {
  const message = messageProp || children;

  const renderParagraph = (content, index = 0) => (
    <p className={style.paragraph} key={index}>
      {content}
    </p>
  );

  return (
    <div
      className={clsx(style.wrapper, style[type])}
      role="alert"
      aria-atomic="true"
      {...attrs}
    >
      <div className={style.icon}>
        <i
          className={clsx("fa", iconClasses[type])}
          aria-hidden
        />
      </div>
      <div className={style.content}>
        {Array.isArray(message)
          ? message.map(renderParagraph)
          : renderParagraph(message)}
      </div>
    </div>
  );
};

Alert.propTypes = {
  /** Alert type */
  type: PropTypes.oneOf([
    "info",
    "success",
    "warning",
    "danger"
  ]),
  /** Alert message. Will overwrite `children`. */
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  /** Alert message as a node */
  children: PropTypes.node
};

Alert.defaultProps = {
  type: "info"
};

export default Alert;
