import { FC, Fragment, useMemo } from "react";
import { uniqueId } from "lodash";
import classes from "./pricing-section-skeleton.module.scss";
import clsx from "clsx";
import Skeleton from "components/Skeleton";

interface Props {}

const PricingSectionSkeleton: FC<Props> = () => {
  const rowsCount = 3;

  const rows = useMemo(() => {
    return Array.from({ length: rowsCount }).map((_, index) =>
      uniqueId(`loader-row-${index}`)
    );
  }, []);
  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.firstRowContainer}>
          <Skeleton className={classes.firstLoader} />
        </div>
        <div>
          {rows.map((id, index) => {
            return (
              <div key={id} className={classes.loaderRow}>
                <div
                  className={clsx({
                    [classes.flexOne]: true
                  })}
                >
                  <Skeleton
                    className={clsx(classes.priceLabel, {
                      [classes.short]: index > rows.length - 2
                    })}
                  />
                </div>
                <div className={classes.priceContainer}>
                  <Skeleton className={classes.price} />
                </div>
                <div className={classes.priceContainer}>
                  <Skeleton className={classes.price} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default PricingSectionSkeleton;
