export default {
  en: {
    modalTitle: "Please contact our support team to republish your listing",
    modalTextPart1: "Please contact us by email at",
    modalTextPart2:
      "or use our live chat service so we can help you optimize your listing for re-listing.",
    supportMail: "support@wechalet.com"
  },
  fr: {
    modalTitle:
      "Merci de contacter notre équipe de support pour republier votre annonce",
    modalTextPart1: "Veuillez nous joindre par courriel au",
    modalTextPart2:
      "ou utiliser notre service de chat en direct afin que nous puissions vous aider à optimiser votre annonce en vue de sa remise en ligne.",
    supportMail: "support@wechalet.com"
  }
};
