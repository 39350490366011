import { useMemo, useRef } from "react";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import CloseIcon from "components/icons/CloseIcon";
import BackIcon from "components/icons/BackIcon";

import { getModals } from "modules/layout/selectors";
import { hideModal } from "modules/layout/actions";

import useBreakpoints from "utils/useBreakpoints";
import useBodyScroll from "utils/useBodyScroll";

import style from "./Modal.module.scss";

/**
 * Modal component
 *
 * @param {object} props - Component props. Accepts all react-modal props and some other ones.
 * @param {string} [props.modalId] - The store's modal id.
 * The modal will only be visible when its `visible` state in the store is true.
 * This prop is ignored when isOpen is provided.
 * @param {string} [props.title] - The modal title. Adds a modal header when provided.
 * @param {string} [props.onClose] - Handler called after the modal is closed.
 * To overwrite close behavior use onRequestClose instead.
 * @param {string| Function | null} [props.renderCorner] - Render something in the empty corner.
 * @param {string} [props.noPaddingTop] - Removes padding top. Useful when the modal content already handles the header.
 * @param {string} [props.contentLabel] - See react-modal docs. Should be provided when there is no title
 * @param {Array | import("react").ReactNode} props.children - The modal's content.
 */
const Modal = ({
  modalId,
  title,
  onClose,
  renderCorner,
  noPaddingTop,
  children,

  // react-modal props
  onAfterOpen,
  onAfterClose,
  onRequestClose,
  isOpen,
  ariaHideApp,
  className,
  overlayClassName,
  contentLabel,
  aria,
  ...reactModalProps
}) => {
  // Hooks

  const dispatch = useDispatch();
  const modals = useSelector(getModals);
  const breakpoints = useBreakpoints();
  const bodyScroll = useBodyScroll();

  const modalStateHasVisible = (modalId && modals[modalId]?.visible) === true;
  const hasHeader = title != null;

  // Refs

  // Get the app element: ReactModal requires it
  const appEl = useRef(document.getElementById("root"));

  // Create random header id so it doesn't conflict with other ids
  const titleId = useRef(
    `Modal_title__${Math.random().toString(36).substring(7)}`
  );

  // Memos

  const localAria = useMemo(() => {
    // If we have a title but no labelling, use the header as the dialog label
    if (title != null && contentLabel == null && !aria?.labelledby) {
      const ariaObj = aria || {};

      return {
        ...ariaObj,
        labelledby: titleId.current
      };
    }

    return aria;
  }, [title, contentLabel, aria]);

  const modalIsOpen = useMemo(() => {
    // When isOpen is provided
    if (isOpen != null) {
      return isOpen;
    }

    // Default behavior
    return modalStateHasVisible;
  }, [isOpen, modalStateHasVisible]);

  // Functions

  const closeModal = () => {
    // Default behavior
    if (onRequestClose == null && modalId != null) {
      dispatch(hideModal(modalId));
      // When onRequestClose is provided
    } else if (typeof onRequestClose === "function") {
      onRequestClose();
    }

    if (typeof onClose === "function") {
      onClose();
    }
  };

  // Handlers

  const onModalAfterOpen = () => {
    bodyScroll.lock();

    if (typeof onAfterOpen === "function") {
      onAfterOpen();
    }
  };

  const onModalAfterClose = () => {
    bodyScroll.unlock();

    if (typeof onAfterClose === "function") {
      onAfterClose();
    }
  };

  const onModalRequestClose = () => {
    closeModal();
  };

  const onCloseClick = () => {
    closeModal();
  };

  return (
    <ReactModal
      isOpen={modalIsOpen}
      onAfterOpen={onModalAfterOpen}
      onAfterClose={onModalAfterClose}
      onRequestClose={onModalRequestClose}
      className={clsx(className, style.box)}
      overlayClassName={clsx(overlayClassName, style.overlay)}
      contentLabel={contentLabel}
      aria={localAria}
      appElement={appEl.current}
      {...reactModalProps}
    >
      <div
        className={clsx(style.wrapper, {
          "has-no-padding-top": noPaddingTop
        })}
        data-cy="modal.wrapper"
      >
        {/* Close button */}
        {!(hasHeader && !breakpoints.md) && (
          <>
            <button
              type="button"
              className={style.close}
              onClick={onCloseClick}
            >
              <CloseIcon />
            </button>
            {typeof renderCorner === "function" && (
              <div className={style.corner}>{renderCorner()}</div>
            )}
          </>
        )}

        {/* Modal header */}
        {hasHeader && (
          <div className={style.header}>
            {!breakpoints.md ? (
              <button
                type="button"
                className={style.back}
                onClick={onCloseClick}
              >
                <BackIcon />
              </button>
            ) : null}
            <h2 id={titleId.current} className={style.title}>
              {title}
            </h2>
            {!breakpoints.md && typeof renderCorner === "function" && (
              <div className={style.headerCorner}>{renderCorner()}</div>
            )}
          </div>
        )}

        {/* Modal body */}
        <div className={style.body}>{children}</div>
      </div>
    </ReactModal>
  );
};

export default Modal;

Modal.defaultProps = {
  modalId: null,
  title: null,
  onRequestClose: null,
  isOpen: null,
  contentLabel: null,
  aria: null,
  overlayClassName: null,
  className: null
};

Modal.propTypes = {
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  modalId: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  renderCorner: PropTypes.func,
  onClose: PropTypes.func,
  onRequestClose: PropTypes.func,
  isOpen: PropTypes.bool,
  noPaddingTop: PropTypes.bool
};
