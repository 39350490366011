import { ChangeEvent, FC, Fragment, ReactNode, useMemo } from "react";
import { uniqueId } from "lodash";
import classes from "./text-input.module.scss";
import clsx from "clsx";

type ClassNames = Partial<Record<"label" | "input" | "inputWrapper", string>>;

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

interface Props extends Omit<InputProps, "onChange"> {
  onChange?: (newValue: string) => void;
  errors?: string | string[];
  hasError?: boolean;
  label?: string | ReactNode;
  value?: string | number;
  id?: string;
  classNames?: ClassNames;
  required?: boolean;
}

export const TextInput: FC<Props> = (props) => {
  const {
    classNames = {} as ClassNames,
    errors,
    value,
    id,
    label,
    onChange = () => {},
    hasError = false,
    required,
    ...rest
  } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.value);
  };

  const _id = useMemo(() => {
    return id || uniqueId("input-field--");
  }, [id]);
  return (
    <Fragment>
      {!!label && (
        <label className={clsx(classNames.label)} htmlFor={id}>
          <span className={classes.textDanger}>{required && "* "}</span>
          {label}
        </label>
      )}
      <div className={clsx(classes.inputWrapper, classNames.inputWrapper)}>
        <input
          id={_id}
          className={clsx(classes.input, classNames.input, {
            [classes.hasError]: !!errors || hasError
          })}
          onChange={handleChange}
          value={value}
          {...rest}
        />
        {!!errors && <div className={classes.textDanger}>{errors}</div>}
      </div>
    </Fragment>
  );
};

export default TextInput;
