export default {
  en: {
    original: "Original",
    newValue: "New value",
    total: "Total",
    guestPayment: "Guest Payment",
    basePrice: "Base price",
    cleaningFee: "Cleaning fee",
    securityDeposit: "Security Deposit",
    discount: "Discount",
    night: "Night",
    nights: "Nights",
    giftDiscount: "Gift discount",
    lodgingTax: "Lodging tax",
    apply: "Apply",
    couponCode: "Coupon code",
    reset: "Reset",
    // Services types
    per_guest: "per guest",
    per_night_guest: "per night per guest",
    per_night: "per night",
    single: "single fee"
  },
  fr: {
    guestPayment: "Paiement Invité",
    total: "Total",
    original: "Original",
    newValue: "New value",
    discount: "Rabais",
    night: "Nuit",
    nights: "Nuits",
    giftDiscount: "Certificat cadeau",
    basePrice: "Base price",
    cleaningFee: "Frais de nettoyage",
    securityDeposit: "Security Deposit",
    lodgingTax: "Taxe d'Hébergement",
    apply: "Appliquer",
    couponCode: "Coupon Rabais",
    reset: "Annuler",
    // Services types
    per_guest: "par personne",
    per_night: "par nuit",
    single: "frais Unique",
    per_night_guest: "par nuit par personne"
  }
};
