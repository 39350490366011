export default {
  en: {
    extraService: "Extra service",
    extraServices: "Extra services"
  },
  fr: {
    extraService: "Service en extra",
    extraServices: "Services en extra"
  }
};
