import { Booking, ErrorResponse, ObjectId } from "modules/shared/types";
import { FC, Fragment, useMemo } from "react";
import { GuestPaymentSections } from "../guest-payment-section";
import { HostPayoutSection } from "../host-payout-section";
import { noop } from "lodash";
import { OnPriceChange } from "modules/hosting-management/containers/edit-booking-form/types";
import { ReservationParameter } from "modules/shared/types/reservation-parameter";
import { UpdateBookingRequest } from "modules/hosting-management/containers/send-new-quote-form/types";
import { useSelector } from "react-redux";
import classes from "./pricing-fields.module.scss";
import clsx from "clsx";
import selectAuthId from "modules/auth/selectors/select-auth-id";
import useValidationSchema from "utils/use-validation-schema";

interface Props {
  booking?: Booking;
  errorResponse?: ErrorResponse;
  loading?: boolean;
  onApply?: () => void;
  onChange: OnPriceChange;
  onReset?: () => void;
  params: UpdateBookingRequest;
  hostId: ObjectId;
  validator: ReturnType<typeof useValidationSchema>;
  reservation?: ReservationParameter;
}

export const PricingFields: FC<Props> = (props) => {
  const {
    onChange,
    params,
    booking,
    reservation,
    onApply = noop,
    hostId,
    validator,
    onReset = noop,
    loading,
    errorResponse
  } = props;

  const authId = useSelector(selectAuthId);

  const showHostSection = useMemo(() => {
    return authId === hostId;
  }, [authId, hostId]);

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={clsx(classes.column, classes.card)}>
          <GuestPaymentSections
            onChange={onChange}
            params={params}
            booking={booking}
            hostId={hostId}
            onApply={onApply}
            onReset={onReset}
            reservation={reservation}
            errorResponse={errorResponse}
            validator={validator}
            loading={loading}
          />
        </div>

        {showHostSection && (
          <div className={clsx(classes.column, classes.card)}>
            <HostPayoutSection booking={booking} reservation={reservation} />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default PricingFields;
