import { PayloadAction } from "modules/shared/types/payload-action";
import { ContainerState } from "../types";
import { produce } from "immer";
import { ObjectId } from "modules/shared/types/object-id";

export default (
  state: ContainerState,
  action: PayloadAction<{ ids: ObjectId[]; status?: boolean }>
): ContainerState => {
  const { type, payload } = action || {};
  switch (type) {
    case ALL_LISTINGS_TOGGLED:
      return produce(state, (draft) => {
        payload.ids.forEach((id) => {
          draft.listingsToImport[id] = !!payload.status;
        });
      });
    default:
      return state;
  }
};

export const ALL_LISTINGS_TOGGLED = "ALL_LISTINGS_TOGGLED";
