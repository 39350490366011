import { getLocale } from "modules/settings/selectors";
import { Locale } from "modules/shared/types";
import { useSelector } from "react-redux";
import useLang from "@design-library/utils/useLang";

/**
 * Retrieve a language object for translation using the provided `messages` object.
 * accepts a `messages` object representing translations
 * The returned `lang` variable is typed and allows you to access translated
 * strings by their keys.
 *
 */
export const useTranslation = <T extends Messages>(
  messages: T
): DeepPartial<T["en"]> => {
  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  return lang as DeepPartial<T["en"]>;
};

type Nest = {
  [key: string]: string | Nest;
};

type Messages = Record<Locale, Nest>;

type DeepPartial<T> = {
  [P in keyof T]: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

export default useTranslation;
