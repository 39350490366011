export default {
  en: {
    title: "Modify booking",
    edit: "Edit",
    reviewBooking:
      "Review the new booking details before sending them to the guest.",
    messageToTraveler: "Message to traveler (optional)",
    review: "Review",
    update: "Update booking",
    basePriceError: "The night price must be of at least $10.",
    modifyBookingError: "An error occurred contact our support team.",
    modifyBookingErrorTitle: "Error"
  },
  fr: {
    title: "Modifier la réservation",
    edit: "Modifier",
    reviewBooking:
      "Veuillez vérifier les nouveaux détails de réservation avant de les envoyer.",
    messageToTraveler: "Message au voyageur (optionnel)",
    review: "Aperçu",
    update: "Mettre à jour",
    basePriceError: "Le prix de la nuit doit être d'au moins 10 $.",
    modifyBookingError: "Une erreur s'est produite, contactez notre équipe de support.",
    modifyBookingErrorTitle: "Erreur"
  }
};
