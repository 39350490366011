import { CancelationPolicy } from "modules/hosting-management/types";
import { FC, Fragment } from "react";
import { getLocale } from "modules/settings/selectors";
import { Info } from "react-feather";
import { useSelector } from "react-redux";
import classes from "./cancellation-policy-select.module.scss";
import messages from "./messages";
import useLang from "@design-library/utils/useLang";

interface Props {
  value?: CancelationPolicy;
  onChange?: (newValue: CancelationPolicy) => void;
}

export const CancellationPolicySelect: FC<Props> = (props) => {
  const { onChange = () => {}, value } = props;

  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  return (
    <Fragment>
      <div>
        <div>
          <div className={classes.row}>
            <div className={classes.column}>
              <legend className={classes.title}>
                {lang.cancellationPolicy}
              </legend>
              <p>{lang.cancellationExplainer}</p>
            </div>
            <div>
              <a
                href={lang.canPolicyLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Info />
              </a>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className={classes.row}>
              <input
                onChange={() => onChange("flexible")}
                checked={value === "flexible"}
                value="flexible"
                id="cancellation-policy-flexible"
                className={classes.radio}
                type="radio"
              />
              <label
                htmlFor="cancellation-policy-flexible"
                className={classes.label}
              >
                <legend className={classes.title}>{lang.flexible}</legend>
                <p className="cancellation_policy">{lang.flexibleExplainer}</p>
              </label>
            </div>
            <hr className={classes.rule} />
            <div className={classes.row}>
              <input
                onChange={() => onChange("moderate")}
                checked={value === "moderate"}
                id="cancellation-policy-moderate"
                value="moderate"
                className={classes.radio}
                type="radio"
              />
              <label
                htmlFor="cancellation-policy-moderate"
                className={classes.label}
              >
                <div>
                  <legend className={classes.title}>{lang.moderate}</legend>
                  <p className="cancellation_policy">
                    {lang.moderateExplainer}
                  </p>
                </div>
              </label>
            </div>
            <hr className={classes.rule} />
            <div className={classes.row}>
              <input
                onChange={() => onChange("strict")}
                checked={value === "strict"}
                id="cancellation-policy-strict"
                value="strict"
                className={classes.radio}
                type="radio"
              />
              <label
                htmlFor="cancellation-policy-strict"
                className={classes.label}
              >
                <legend className={classes.title}>{lang.strict}</legend>
                <p className="cancellation_policy">{lang.strictExplainer}</p>
              </label>
            </div>
            <hr className={classes.rule} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CancellationPolicySelect;
