import { FC, Fragment, ReactNode } from "react";
import classes from "./price-row.module.scss";
import clsx from "clsx";

interface Props {
  label?: string | ReactNode;
  original?: string | ReactNode;
  newValue: string | ReactNode;
}

export const PriceRow: FC<Props> = (props) => {
  const { label, original, newValue } = props;
  return (
    <Fragment>
      {/* Row */}
      <div className={clsx(classes.row, classes.marginBottom)}>
        <div className={clsx(classes.column)}>{label}</div>
        <div className={clsx(classes.column, classes.alignEnd)}>{original}</div>
        <div className={clsx(classes.column, classes.alignEnd)}>{newValue}</div>
      </div>
    </Fragment>
  );
};

export default PriceRow;
