// @ts-expect-error
import * as Yup from "yup";

const errors = {
  NIGHTS_COST_NEGATIVE: "nightsCostMustNotBeNegative",
  NIGHTS_COST_MINIMUM: "nightsCostMinimum",
  INVALID_NIGHTS_COST: "nightsCostIsInvalid",
  CLEANING_FEE_NEGATIVE: "cleaningFeeMustNotBeNegative"
};

/**
 * calculate the number of nights between two dates using dayjs
 *
 * @param checkin
 * @param checkout
 */
// const calculateNightsCount = (checkin?: string, checkout?: string) => {
//   if (!checkin || !checkout) {
//     return 0;
//   }
//   const checkinDate = dayjs(checkin);
//   const checkoutDate = dayjs(checkout);
//   return checkoutDate.diff(checkinDate, "day"); // 'day' will give the number of full days between the dates
// };

const isPositive = (value: string) => {
  if (!value || value === "") {
    return true;
  }
  return Number(value) >= 0;
};

export default Yup.object().shape({
  checkin: Yup.string().required("select_period"),
  checkout: Yup.string().required("select_period"),
  adults: Yup.number().min(1, "min_guests").required("min_guests"),
  children: Yup.number().default(0).nullable().typeError("min_guests"),
  infants: Yup.number().default(0).nullable().typeError("min_guests"),
  pets: Yup.boolean().default(false).nullable().typeError("min_guests"),
  extra_services: Yup.array().of(Yup.string()).default([]).nullable(),
  pay_damage_protection_fee: Yup.boolean().nullable(),
  nights_cost: Yup.string().test(
    "is-positive",
    errors.NIGHTS_COST_NEGATIVE,
    isPositive
  ),
  cleaning_fee: Yup.string().test(
    "is-positive",
    errors.CLEANING_FEE_NEGATIVE,
    isPositive
  )
  // .test(
  //   "cost-check",
  //   errors.NIGHTS_COST_MINIMUM,
  //   function (count: string) {
  //     if (!count || count === "") {
  //       return true;
  //     }
  //     // @ts-expect-error
  //     const { parent } = this as { parent: BookingParams };
  //     const { checkin, checkout } = parent;
  //     const nights_count = calculateNightsCount(checkin, checkout);
  //     return Number(count) / nights_count >= 10;
  //   }
  // )
});
