import { ChevronLeft, ChevronRight } from "react-feather";
import { DatePicker } from "@design-library/DatePicker";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { getLocale } from "modules/settings/selectors";
import { useDispatch, useSelector } from "react-redux";
import changeDateRange from "modules/listing-calendar/store/actions/change-date-range";
import classes from "./date-range-picker.module.scss";
import clsx from "clsx";
import DateRangeLabel from "../date-range-label/date-range-label";
import dayjs from "dayjs";
import realDate from "modules/listing-calendar/utils/real-date";
import selectDateRange from "modules/listing-calendar/store/selectors/select-date-range";
import useBreakpoints from "utils/useBreakpoints";
import useLang from "@design-library/utils/useLang";
import messages from "./messages";
import {
  DAY_WIDTH,
  INITIAL_X_OFFSET
} from "modules/listing-calendar/constants";

interface Props {}

export const DateRangePicker: FC<Props> = () => {
  const breakpoints = useBreakpoints();

  const [isPickerOpen, setPickerOpen] = useState(false);

  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  const [from, to] = useSelector(selectDateRange);

  const fromDay = dayjs(from);

  const dispatch = useDispatch();

  const [scrollableElement, setScrollableElement] =
    useState<HTMLElement | null>(null);

  const dayWidth = useMemo(() => {
    return breakpoints.md ? DAY_WIDTH.md : DAY_WIDTH.sm;
  }, [breakpoints.md]);

  const onApply = (dates: any) => {
    const start = dayjs(dates.start).toDate();
    const end = dayjs(dates.end).toDate();
    dispatch(changeDateRange([realDate(start), realDate(end)]));
    setPickerOpen(false);
  };

  const onCancel = () => {
    setPickerOpen(false);
  };

  const moveInMonths = (by: number) => {
    const start = fromDay.add(by, "M").startOf("month");
    const end = start.endOf("month").startOf("D");
    dispatch(
      changeDateRange([realDate(start.toDate()), realDate(end.toDate())])
    );
    setPickerOpen(false);
  };

  useEffect(() => {
    setScrollableElement(document.getElementById("scrollableElement"));
  }, []);

  const scrollToToday = useCallback(() => {
    if (scrollableElement) {
      const todayIndex = dayjs().diff(fromDay, "day");
      const todayOffset = dayWidth * todayIndex + INITIAL_X_OFFSET;

      scrollableElement.scrollTo({
        left: todayOffset,
        behavior: "smooth"
      });
    }
  }, [dayWidth, scrollableElement, fromDay]);

  return (
    <div className={classes.container}>
      <div className={classes.rowContainer}>
        <div onClick={() => moveInMonths(-1)} className={clsx(classes.arrow)}>
          <ChevronLeft size={25} />
        </div>
        <div className={classes.dateLabel}>
          <DateRangeLabel onClick={() => setPickerOpen(!isPickerOpen)} />
          <div className={classes.pickerOpenContainer}>
            {isPickerOpen && (
              <div className={classes.datePicker}>
                <DatePicker
                  isPreviousDatesDisabled={false}
                  locale={locale}
                  isLoading={false}
                  livePrice={undefined}
                  onApply={onApply}
                  onCancel={onCancel}
                  initialStartDate={from ? new Date(from) : undefined}
                  initialEndDate={to ? new Date(to) : undefined}
                  onError={() => {}}
                  availabilityCalendar={{}}
                  isFullWidth={!breakpoints.sm}
                />
              </div>
            )}
          </div>
        </div>
        <div onClick={() => moveInMonths(1)} className={clsx(classes.arrow)}>
          <ChevronRight size={25} />
        </div>
        <button onClick={scrollToToday} className={classes.todayButton}>
          {lang.description}
        </button>
      </div>
    </div>
  );
};

export default memo(DateRangePicker);
