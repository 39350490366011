export default {
  en: {
    basePrice: "Base price",
    cleaningFee: "Cleaning fee",
    discount: "Discount",
    giftDiscount: "Gift discount",
    hostPayout: "Host payout",
    newValue: "New value",
    original: "Original",
    securityDeposit: "Security Deposit",
    total: "Total",
    // Services types
    per_guest: "per guest",
    per_night: "per night",
    single: "single fee",
    per_night_guest: "per night per guest"
  },
  fr: {
    basePrice: "Base price",
    cleaningFee: "Frais de nettoyage",
    discount: "Rabais",
    giftDiscount: "Certificat cadeau",
    hostPayout: "Paiement de l'hôte",
    newValue: "New value",
    original: "Original",
    securityDeposit: "Security Deposit",
    total: "Total",
    // Services types
    per_guest: "par personne",
    per_night: "par nuit",
    single: "frais unique",
    per_night_guest: "par nuit par personne"
  }
};
