import { FC, useMemo } from "react";
import { getLocale } from "modules/settings/selectors";
import { useSelector } from "react-redux";
import { ValidationErrors } from "modules/shared/types/response-error";
import Alert from "@design-library/Alert";
import messages from "./messages";
import Modal from "components/Modal";
import useLang from "redux/hooks/useLang";

interface Props {
  errors?: ValidationErrors;
  isOpen?: boolean;
  message?: string;
  onDismiss?: () => void;
}

export const PublishAlertModal: FC<Props> = (props) => {
  const { message, onDismiss = () => {}, errors } = props;

  const locale = useSelector(getLocale);

  const lang = useLang(messages, locale);

  const errorMessages = useMemo(() => {
    if (!errors) {
      return [];
    }
    return Object.values(errors);
  }, [errors]);

  return (
    <Modal
      isOpen
      onRequestClose={onDismiss}
      title={lang.getString("failedToPublish")}
    >
      <Alert type="danger" message={undefined}>
        {/* general error message */}
        <div>{message}</div>
        {/* validation errors */}
        {errorMessages?.map((message) => (
          <div>{message}</div>
        ))}
      </Alert>

      <div />
    </Modal>
  );
};

export default PublishAlertModal;
