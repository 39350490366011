import { createSelector } from "reselect";
import { selectDomain } from ".";

/**
 * Returns the application's base currency
 *
 */
export default createSelector([selectDomain], (state) => {
  return state.base;
});
