import { useMemo } from "react";
import { useTranslation } from "modules/shared";
import messages from "./messages";
import useInvoiceDetails from "modules/hosting-management/utils/use-invoice-details";

export default (invoice?: ReturnType<typeof useInvoiceDetails>) => {
  const lang = useTranslation(messages);

  const taxes = useMemo(() => {
    const items = [
      {
        name: lang.accommodationTax,
        price: invoice?.accommodationTax
      },
      {
        name: lang.taxNames.GST,
        price: invoice?.hostTaxes?.canadian_federal_tax || 0
      },
      {
        name: lang.taxNames.QST,
        price: invoice?.hostTaxes?.quebec_provincial_tax || 0
      },
      {
        name: lang.taxNames.HST,
        price: invoice?.hostTaxes?.harmonized_sales_tax || 0
      }
    ].filter((tax) => Number(tax.price));
    return {
      items,
      total: items
        .reduce((previousTotal, tax) => previousTotal + Number(tax.price), 0)
        .toFixed(2)
    };
  }, [lang, invoice]);

  const serviceFeeTaxes = useMemo(() => {
    const label = lang.wechaletFee.toLocaleLowerCase();
    const items = [
      {
        name: lang.hostWechaletFee,
        price: Number(-(invoice?.wechaletFee || 0))
      },
      {
        name: `${lang.taxNames.GST} (${label})`,
        price: Number(-(invoice?.serviceFeeCanadianFederalTax || 0))
      },
      {
        name: `${lang.taxNames.QST} (${label})`,
        price: Number(-(invoice?.serviceFeeQuebecProvincialTax || 0))
      },
      {
        name: `${lang.taxNames.HST} (${label})`,
        price: Number(-(invoice?.serviceFeeHarmonizedSalesTax || 0))
      }
    ].filter((serviceFee) => serviceFee.price);
    return {
      items,
      total: items
        .reduce((previousTotal, tax) => previousTotal + Number(tax.price), 0)
        .toFixed(2)
    };
  }, [lang, invoice]);

  return {
    taxes,
    serviceFeeTaxes
  };
};
