import { ChevronDown, ChevronLeft } from "react-feather";
import { FC, Fragment, useState, useMemo } from "react";
import { PriceRow } from "../price-row";
import { useTranslation } from "modules/shared";
import classes from "./service-fees.module.scss";
import messages from "./messages";
import useCurrencyFormatter from "modules/currency/utils/use-currency-formatter";
import useInvoiceDetails from "modules/hosting-management/utils/use-invoice-details";
import useTravellingTaxes from "./use-travelling-taxes";

type Props = {
  details: ReturnType<typeof useInvoiceDetails>;
  originalDetails: ReturnType<typeof useInvoiceDetails>;
};

type TaxItem = { name: string; originalValue: number; newValue: number };

const TravellingFees: FC<Props> = (props) => {
  const { details, originalDetails } = props;

  const [taxesAreVisible, setTaxesAreVisible] = useState(false);

  const { format } = useCurrencyFormatter();

  const lang = useTranslation(messages);

  const originalTaxes = useTravellingTaxes(originalDetails);

  const taxes = useTravellingTaxes(details);

  const shouldShowGuestTaxes = true;

  /**
   * We only show taxes that have a non-zero value
   * If both the original and new value are zero, we don't show it
   *
   */
  const nonZeroTaxes = useMemo(() => {
    return taxes.taxes
      .map((t) => {
        const rawOriginalValue = originalTaxes.taxes.find(
          (x) => x.name === t.name
        )?.price;
        const originalValue = Number(rawOriginalValue);
        const newValue = Number(t.price);
        if (!originalValue && !newValue) {
          return null;
        }
        return { name: t.name, originalValue, newValue };
      })
      .filter((t) => t !== null) as TaxItem[];
  }, [taxes, originalTaxes]);

  /**
   * Only show taxes if there are any
   * if both the original and new value are zero, we don't show it
   *
   */
  const hasNoTaxes = useMemo(() => {
    const total = Number(taxes.total);

    const originalTotal = Number(originalTaxes.total);

    return !total && !originalTotal;
  }, [taxes, originalTaxes]);

  return (
    <Fragment>
      <div>
        {!hasNoTaxes && (
          <PriceRow
            label={
              <div
                role="button"
                className={classes.buttonLike}
                onClick={() => setTaxesAreVisible(!taxesAreVisible)}
              >
                <div>
                  <span>{lang.taxes}</span>
                  {taxesAreVisible ? (
                    <ChevronLeft size={20} className={classes.marginStart} />
                  ) : (
                    <ChevronDown size={20} className={classes.marginStart} />
                  )}
                </div>
              </div>
            }
            newValue={format(taxes.total)}
            original={format(originalTaxes.total)}
          />
        )}
        {shouldShowGuestTaxes && (
          <>
            {taxesAreVisible &&
              nonZeroTaxes.map((t) => (
                <PriceRow
                  key={`${t.name}`}
                  label={<div className={classes.indent}>{t.name}</div>}
                  newValue={format(t.newValue)}
                  original={format(t.originalValue)}
                />
              ))}
          </>
        )}
      </div>
    </Fragment>
  );
};

export default TravellingFees;
