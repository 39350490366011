import { createSelector } from "reselect";
import selectDomain from "./store/selectors";

/**
 * @deprecated import from ./store/selectors/select-modals
 */
export const getModals = createSelector(
  [selectDomain],
  (layout) => layout.modals
);
