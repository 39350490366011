import { ContainerState } from "../types";
import initialState from "../initial-state";
import { reducer } from "../slice";

const reducers: ((
  state: typeof initialState,
  action: unknown
) => typeof initialState)[] = [
  /**
   * all layout reducers should be put here
   *
   */
  require("../../reducers").default,
  require("./all-cookies-accepted").default,
  require("./consent-cookies-initialized").default,
  require("./custom-cookies-accepted").default,
  reducer
];

/**
 * layout root reducer
 *
 */
export default (state = initialState, action: any) => {
  let newState: ContainerState;
  switch (action.type) {
    // Global reducers go here

    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
};
