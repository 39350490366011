export default {
  en: {
    hostWechaletFee: "Host service fee",
    waiverDepositLink:
      "http://help.wechalet.com/en/articles/7004989-how-does-damage-protection-fees-work-for-wechalet-guests",
    serviceFeeGuestLink:
      "http://help.wechalet.com/en/articles/4412084-what-are-the-wechalet-service-charges-for-travelers",
    serviceFeeHostLink:
      "http://help.wechalet.com/en/articles/4387810-what-are-the-wechalet-service-fees-for-hosts",
    wechaletFee: "Service fee",
    damageProtection: "Damage protection",
    taxes: "Taxes",
    monthlyDiscount: "Monthly discount",
    weeklyDiscount: "Weekly discount",
    accommodationTax: "Lodging Tax",
    taxNames: {
      GST: "GST",
      HST: "HST",
      PST: "PST",
      QST: "QST"
    }
  },
  fr: {
    accommodationTax: "Taxe d'Hébergement",
    hostWechaletFee: "Frais de service hôte ",
    waiverDepositLink:
      "http://help.wechalet.com/fr/articles/7004989-comment-fonctionne-l-option-de-protection-des-dommages-pour-les-invites-wechalet",
    serviceFeeGuestLink:
      "http://help.wechalet.com/fr/articles/4412084-quels-sont-les-frais-de-service-wechalet-pour-les-voyageurs",
    serviceFeeHostLink:
      "http://help.wechalet.com/fr/articles/4387810-quels-sont-les-frais-de-service-wechalet-pour-les-hotes",
    wechaletFee: "Frais de service",
    damageProtection: "Protection des dommages",
    taxes: "Taxes",
    monthlyDiscount: "Rabais mensuel",
    weeklyDiscount: "Rabais semaine",
    taxNames: {
      GST: "TPS",
      HST: "TVH",
      PST: "TVP",
      QST: "TVQ"
    }
  }
};
